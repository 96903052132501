<template>
    <div class="flex flex-col gap-2">
        <component
            v-for="(metric, i) in data.metrics"
            :key="i"
            :is="metric.component"
            :title="metric.name"
            :data="data.results[metric.uriKey]?.data"
            :suffix="data.results[metric.uriKey]?.suffix"
            :format="data.results[metric.uriKey]?.format"
        />
    </div>
</template>

<script>
    import BaseMetric from "./BaseMetric.vue";

    export default {
        extends: BaseMetric,
    };
</script>
