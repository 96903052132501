<template>
    <div class="flex flex-col gap-2">
        <h4
            v-if="title"
            v-text="title"
            class="m-0 p-0"
        />
        <div class="flex-1">
            <div>
                <canvas ref="chart"></canvas>
            </div>
            <div class="text-xs">
                <abbr
                    :title="aValue"
                    v-text="`${formattedA}% of ${frequencyLabel}`"
                />
                {{ linkingVerb }} caused by
                <abbr
                    :title="bValue"
                    v-text="`${formattedB}% of ${typeLabel}`"
                />.
            </div>
        </div>

        <help-tooltips>
            <help-range-tooltip :range="range" />
            <slot name="help" />
        </help-tooltips>
    </div>
</template>

<script>
    import BaseRangedChartMetric from "./BaseRangedChartMetric.vue";
    import orderBy from "lodash/orderBy";
    import pluralize from "pluralize";
    import HelpTooltips from "@nova/components/HelpTooltips.vue";
    import HelpRangeTooltip from "@nova/components/HelpRangeTooltip.vue";

    export default {
        extends: BaseRangedChartMetric,
        components: {
            HelpTooltips,
            HelpRangeTooltip,
        },
        computed: {
            frequencyLabel() {
                return this.data.frequencyLabel || "Problems";
            },
            typeLabel() {
                return this.data.typeLabel || "Causes";
            },
            linkingVerb() {
                return this.frequencyLabel === pluralize(this.frequencyLabel)
                    ? "are"
                    : "is";
            },
            formattedA() {
                return this.formatNumber(this.paretoIndex.a * 100, {
                    mantissa: 0,
                });
            },
            formattedB() {
                return this.formatNumber(this.paretoIndex.b * 100, {
                    mantissa: 0,
                });
            },
            aValue() {
                const precision = this.format.mantissa || 0;
                const multiplier = Math.pow(10, precision);
                const total = this.total;
                const value =
                    Math.ceil(this.paretoIndex.a * total * multiplier) /
                    multiplier;

                return (
                    this.formatNumber(value, this.format) +
                    " of " +
                    this.formatNumber(total, this.format) +
                    " " +
                    (this.suffix || "")
                ).trim();
            },
            bValue() {
                const length = this.items.length;
                const value = Math.ceil(this.paretoIndex.b * length);

                return `${value} of ${length}`;
            },
            total() {
                if (!this.data?.partition) {
                    return 0;
                }

                return (
                    this.data.total ||
                    this.data.partition.reduce((c, item) => c + item.value, 0)
                );
            },
            length() {
                return this.data?.maxLength || this.items.length;
            },
            items() {
                if (!this.data?.partition) {
                    return [];
                }

                const partition = orderBy(
                    this.data.partition,
                    ["value"],
                    ["desc"],
                );

                const total = this.total;

                let sum = 0;
                let cumulative = 0;

                return partition.map((item) => {
                    const percent = item.value / total;
                    cumulative += percent;
                    sum += item.value;

                    return {
                        ...item,
                        percent,
                        cumulative,
                        sum,
                    };
                });
            },
            limitedItems() {
                return this.items.slice(0, this.length);
            },
            paretoIndex() {
                let best = {
                    index: 0,
                    value: null,
                    a: null,
                    b: null,
                };

                const total = this.total;
                const length = this.length;

                this.items.forEach((item, index) => {
                    const a = item.sum / total;
                    const b = (index + 1) / length;

                    const value = Math.abs(a + b - 1);

                    if (value < best.value || best.value === null) {
                        best = { index, value, a, b };
                    }
                });

                return best;
            },
            chartType() {
                return "bar";
            },
            chartData() {
                if (!this.data?.partition) {
                    return {};
                }

                const a = this.paretoIndex.a;

                return {
                    labels: this.limitedItems.map((item) => item.label),
                    datasets: [
                        {
                            is: "cfp",
                            label: "Cumulative",
                            type: "line",
                            data: this.limitedItems.map(
                                (item) => item.cumulative * 100,
                            ),
                            borderColor: this.colors.secondary,
                            backgroundColor: this.colors.secondary,
                            pointRadius: 0,
                            tension: 0.4,
                            yAxisID: "y2",
                        },
                        {
                            is: "index",
                            type: "line",
                            data: this.limitedItems.map(() => a * 100),
                            borderColor: this.colors.grid,
                            borderWidth: 1,
                            borderDash: [5, 5],
                            pointRadius: 0,
                            pointHoverRadius: 3,
                            pointRadius: 0,
                            yAxisID: "y2",
                        },
                        {
                            is: "frequency",
                            label: this.frequencyLabel,
                            data: this.limitedItems.map((item) => item.value),
                            percent: this.limitedItems.map(
                                (item) => item.percent * 100,
                            ),
                            backgroundColor: this.colors.primary,
                            yAxisID: "y",
                        },
                    ],
                };
            },
            chartOptions() {
                return {
                    interaction: {
                        mode: "index",
                        intersect: false,
                    },
                    maintainAspectRatio: false,
                    stacked: false,
                    scales: {
                        x: {
                            display: false,
                        },
                        y: {
                            type: "linear",
                            position: "left",
                            grid: {
                                drawOnChartArea: false,
                            },
                            ticks: {
                                callback: (value) =>
                                    this.formatNumber(value, this.format),
                            },
                        },
                        y2: {
                            type: "linear",
                            display: true,
                            position: "right",
                            grid: {
                                tickColor: "white",
                            },
                            ticks: {
                                callback: (value) => value + "%",
                            },
                            border: {
                                display: false,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            ...this.chartTooltip,
                            displayColors: true,
                            callbacks: {
                                label: (item) => {
                                    if (item.dataset.is === "cfp") {
                                        const v = this.formatNumber(item.raw, {
                                            mantissa: 1,
                                        });

                                        return `${item.dataset.label}: ${v}%`;
                                    }

                                    const v = this.formatNumber(
                                        item.raw,
                                        this.format,
                                    );

                                    const s = this.suffix
                                        ? ` ${this.suffix}`
                                        : "";

                                    const rawP =
                                        item.dataset.percent[item.dataIndex];
                                    const p = this.formatNumber(rawP, {
                                        mantissa: 1,
                                    });

                                    return `${item.dataset.label}: ${v}${s} (${p}%)`;
                                },
                            },
                            filter: (item) => {
                                return item.dataset.label;
                            },
                        },
                    },
                };
            },
        },
    };
</script>
