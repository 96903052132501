<template>
    <table-item
        :field="field"
        class="justify-between w-full"
    >
        <template #value="{ item }">
            <template v-if="item.usesCustomizedDisplay">
                {{ item.fieldValue }}
            </template>
            <template v-else>
                <div>{{ this.field.currency }}</div>
                <div>{{ formattedValue }}</div>
            </template>
        </template>
    </table-item>
</template>

<script>
    import { formatNumber } from "@nova/utils";

    export default {
        props: ["field"],
        computed: {
            formattedValue() {
                return formatNumber(this.field.value, {
                    mantissa: this.mantissa,
                });
            },
            mantissa() {
                if (this.field.step == 0 || !this.field.step) {
                    return 0;
                }

                if (this.field.step >= 1) {
                    return 0;
                }

                return Math.abs(Math.floor(Math.log10(this.field.step)));
            },
        },
    };
</script>
