<template>
    <modal
        :show="show"
        role="alertdialog"
        size="xl"
        @close="handleClose"
        :header="header || `${uppercaseMode} ${label}`"
    >
        <form @submit.prevent="handleConfirm">
            <modal-content>
                <slot
                    :mode="__(mode)"
                    :uppercase-mode="__(uppercaseMode)"
                >
                    <p class="leading-normal">
                        {{ confirmation }}
                    </p>
                </slot>
            </modal-content>

            <modal-footer>
                <v-button
                    type="button"
                    variant="link"
                    :label="__('Cancel')"
                    severity="muted"
                    dusk="cancel-delete-button"
                    @click.prevent="handleClose"
                    size="sm"
                />

                <v-button
                    type="submit"
                    ref="confirmButton"
                    dusk="confirm-delete-button"
                    :loading="working"
                    severity="alert"
                    :label="__(uppercaseMode)"
                    size="sm"
                />
            </modal-footer>
        </form>
    </modal>
</template>

<script>
    import startCase from "lodash/startCase";
    import Modal from "@nova/components/Modals/Modal.vue";
    import ModalContent from "@nova/components/Modals/ModalContent.vue";
    import ModalFooter from "@nova/components/Modals/ModalFooter.vue";
    import VButton from "@nova/components/Buttons/VButton.vue";

    export default {
        emits: ["confirm", "close"],

        inject: ["resourceInformation"],

        components: {
            Modal,
            ModalContent,
            ModalFooter,
            VButton,
        },

        props: {
            show: {
                type: Boolean,
                default: false,
            },

            header: String,

            mode: {
                type: String,
                default: "delete",
                validator: function (value) {
                    return (
                        ["force delete", "delete", "detach"].indexOf(value) !==
                        -1
                    );
                },
            },

            count: {
                type: Number,
                default: 1,
            },
        },

        data: () => ({
            working: false,
        }),

        watch: {
            show(showing) {
                if (showing === false) {
                    this.working = false;
                }
            },
        },

        methods: {
            handleClose() {
                this.$emit("close");
                this.working = false;
            },

            handleConfirm() {
                this.$emit("confirm");
                this.working = true;
            },
        },

        computed: {
            uppercaseMode() {
                return startCase(this.mode);
            },

            label() {
                return this.count === 1
                    ? this.resourceInformation.singularLabel
                    : this.resourceInformation.label;
            },

            confirmation() {
                return this.count === 1
                    ? `Are you sure you want to ${this.mode} the selected ${this.label}?`
                    : `Are you sure you want to ${this.mode} the ${this.count} selected ${this.label}?`;
            },
        },
    };
</script>
