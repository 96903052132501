<template>
    <div class="progress-bar">
        <div
            class="progress-meter"
            :class="type"
            :style="`width: ${percent}%;`"
            role="progressbar"
            :aria-valuenow="percent"
            aria-valuemin="0"
            aria-valuemax="100"
            aria-valuenow="0"
        >
            <slot :percent="percent">
                <span class="progress-meter-text"> {{ percent }}%</span>
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "VProgressBar",
        props: {
            percent: {
                type: Number,
                default: 0,
            },
            type: {
                type: String,
                default: "",
                validate: (value) => {
                    if (!value) return true;

                    return ["alert", "primary", "success", "warning"].includes(
                        value,
                    );
                },
            },
        },
    };
</script>
