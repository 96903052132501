export default function (Alpine) {
    Alpine.directive(
        "tooltip",
        (el, { expression }, { evaluateLater, effect, cleanup }) => {
            let getMessage = evaluateLater(expression);

            let instance = tippy(el, { allowHTML: true });

            effect(() => {
                getMessage((message) => {
                    if (!message) return instance.destroy();

                    instance.setContent(message);
                });
            });

            cleanup(() => instance.destroy());
        },
    );
}
