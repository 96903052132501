<template>
    <div class="flex flex-wrap gap-x-2 gap-y-1">
        <template v-if="hasValues">
            <span
                v-for="item in fieldValues"
                v-text="item"
                class="text-xs bg-blue-500 text-white px-3 py-1 rounded-full"
            />
        </template>
        <p v-else>{{ __("&mdash;") }}</p>
    </div>
</template>

<script>
    import forEach from "lodash/forEach";
    import indexOf from "lodash/indexOf";

    export default {
        props: ["resourceName", "field"],

        computed: {
            hasValues() {
                return this.fieldValues.length > 0;
            },

            fieldValues() {
                let selected = [];

                forEach(this.field.options, (option) => {
                    let value = option.value.toString();

                    if (
                        indexOf(this.field.value, value) >= 0 ||
                        indexOf(this.field.value?.toString(), value) >= 0
                    ) {
                        selected.push(option.label);
                    }
                });

                return selected;
            },
        },
    };
</script>
