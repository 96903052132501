<template>
    <table-item :field="field">
        <template #value>
            <div
                v-if="field.value"
                class="rounded-md inline-flex items-center justify-center border border-gray-400"
            >
                <div
                    class="w-4 h-4 rounded-sm"
                    :style="{
                        backgroundColor: field.value,
                    }"
                />
            </div>
            <span v-else>{{ __("&mdash;") }}</span>
        </template>
    </table-item>
</template>

<script>
    export default {
        props: ["field"],
    };
</script>
