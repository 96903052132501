<template>
    <modal
        :show="show"
        @close="handlePreventModalAbandonmentOnClose"
        role="dialog"
        :size="action.modalSize"
        :modal-style="action.modalStyle"
        :use-focus-trap="usesFocusTrap"
        :header="action.name"
    >
        <form
            autocomplete="off"
            @change="onUpdateFormStatus"
            @submit.prevent.stop="$emit('confirm')"
            :data-form-unique-id="formUniqueId"
            :class="{
                'rounded-lg shadow-lg overflow-hidden':
                    action.modalStyle === 'window',
                'flex flex-col justify-between h-full':
                    action.modalStyle === 'fullscreen',
            }"
        >
            <modal-content
                :class="{
                    'overflow-hidden overflow-y-auto':
                        action.modalStyle === 'fullscreen',
                }"
            >
                <!-- Confirmation Text -->
                <p
                    v-if="action.confirmText"
                    :class="{ 'text-red-500': action.destructive }"
                >
                    {{ action.confirmText }}
                </p>

                <!-- Action Fields -->
                <div v-if="action.fields.length > 0">
                    <field-grid class="mt-4">
                        <component
                            v-for="field in action.fields"
                            :key="field.attribute"
                            :is="'form-' + field.component"
                            :errors="errors"
                            :resource-name="resourceName"
                            :field="field"
                            :show-help-text="true"
                            :form-unique-id="formUniqueId"
                            :mode="
                                action.modalStyle === 'fullscreen'
                                    ? 'action-fullscreen'
                                    : 'action-modal'
                            "
                            :sync-endpoint="syncEndpoint"
                            @field-changed="onUpdateFieldStatus"
                        />
                    </field-grid>
                </div>
            </modal-content>

            <modal-footer>
                <v-button
                    type="button"
                    dusk="cancel-action-button"
                    @click="$emit('close')"
                    :label="action.cancelButtonText"
                    variant="link"
                    severity="muted"
                    size="sm"
                />

                <v-button
                    type="submit"
                    ref="runButton"
                    dusk="confirm-action-button"
                    :loading="working"
                    :severity="action.destructive ? 'danger' : 'primary'"
                    :label="action.confirmButtonText"
                    size="sm"
                />
            </modal-footer>
        </form>
    </modal>
</template>

<script>
    import isObject from "lodash/isObject";
    import ModalForm from "@nova/extendables/ModalForm.vue";
    import Modal from "@nova/components/Modals/Modal.vue";
    import ModalContent from "@nova/components/Modals/ModalContent.vue";
    import ModalFooter from "@nova/components/Modals/ModalFooter.vue";
    import VButton from "@nova/components/Buttons/VButton.vue";
    import FieldGrid from "@nova/components/FieldGrid.vue";

    export default {
        extends: ModalForm,

        components: {
            Modal,
            ModalContent,
            ModalFooter,
            VButton,
            FieldGrid,
        },

        emits: ["confirm", "close"],

        props: {
            action: { type: Object, required: true },
            endpoint: { type: String, required: false },
            errors: { type: Object, required: true },
            resourceName: { type: String, required: true },
            selectedResources: { type: [Array, String], required: true },
            show: { type: Boolean, default: false },
            working: Boolean,
        },

        data: () => ({
            loading: true,
        }),

        created() {
            document.addEventListener("keydown", this.handleKeydown);
        },

        mounted() {
            this.loading = false;
        },

        beforeUnmount() {
            document.removeEventListener("keydown", this.handleKeydown);
        },

        methods: {
            /**
             * Prevent accidental abandonment only if form was changed.
             */
            onUpdateFormStatus() {
                this.updateModalStatus();
            },

            onUpdateFieldStatus() {
                this.onUpdateFormStatus();
            },

            handlePreventModalAbandonmentOnClose(event) {
                this.handlePreventModalAbandonment(
                    () => {
                        this.$emit("close");
                    },
                    () => {
                        event.stopPropagation();
                    },
                );
            },
        },

        computed: {
            syncEndpoint() {
                let searchParams = new URLSearchParams({
                    action: this.action.uriKey,
                });

                if (this.selectedResources === "all") {
                    searchParams.append("resources", "all");
                } else {
                    this.selectedResources.forEach((resource) => {
                        searchParams.append(
                            "resources[]",
                            isObject(resource) ? resource.id.value : resource,
                        );
                    });
                }

                return (
                    (this.endpoint || `/nova-api/${this.resourceName}/action`) +
                    "?" +
                    searchParams.toString()
                );
            },

            usesFocusTrap() {
                return this.loading === false && this.action.fields.length > 0;
            },
        },
    };
</script>
