import registerComponents from "../utils/registerComponents.js";
import { defineAsyncComponent } from "vue";

export default {
    async install(app, options) {
        registerComponents(
            app,
            import.meta.glob("../components/**/*.vue", { eager: true }),
            options,
        );

        app.component(
            "FroalaEditor",
            defineAsyncComponent(() =>
                import("@node_modules/vue-froala-wysiwyg/src/vue-froala").then(
                    (module) => module.Froala,
                ),
            ),
        );

        app.component(
            "FroalaView",
            defineAsyncComponent(() =>
                import("@node_modules/vue-froala-wysiwyg/src/vue-froala").then(
                    (module) => module.FroalaView,
                ),
            ),
        );
    },
};
