<script>
    import BaseMetric from "./BaseMetric.vue";

    export default {
        extends: BaseMetric,
        chart: null,
        watch: {
            chartWatch() {
                this.renderChart();
            },
        },
        computed: {
            chartType() {
                return "line";
            },
            chartData() {
                return {};
            },
            chartOptions() {
                return {};
            },
            chartTooltip() {
                return {
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    titleColor: this.colors.muted,
                    bodyColor: this.colors.text,
                    borderColor: this.colors.grid,
                    footerColor: this.colors.text,
                    borderWidth: 1,
                    padding: 10,
                    displayColors: false,
                };
            },
            chartPlugins() {
                return [];
            },
            chartWatch() {
                return {
                    data: this.chartData,
                    options: this.chartOptions,
                    format: this.format,
                    suffix: this.suffix,
                };
            },
        },
        methods: {
            renderChart() {
                if (!this.chart) {
                    return;
                }

                this.chart.data = this.chartData;
                this.chart.options = this.chartOptions;
                this.chart.update();
            },
            drawDeltaIcon(ctx, sign, x, y) {
                ctx.save();

                const measure = ctx.measureText("");
                const size =
                    measure.fontBoundingBoxAscent +
                    measure.fontBoundingBoxDescent;

                const xoffset =
                    {
                        center: -size / 2,
                        right: -size,
                    }[ctx.textAlign] || 0;

                const yoffset =
                    {
                        center: -size / 2,
                        bottom: -size,
                    }[ctx.textBaseline] || 0;

                ctx.translate(x + xoffset, y + yoffset);

                const icon = this.getDeltaIcon(sign);
                const raw = window.App.icon(icon) || false;

                if (!raw) {
                    return;
                }

                const element = new DOMParser().parseFromString(
                    raw,
                    "image/svg+xml",
                ).documentElement;

                const paths = element.querySelectorAll("path");

                [...paths].forEach((path) => {
                    const d = path.getAttribute("d");

                    ctx.fill(new Path2D(d));
                });

                ctx.restore();
            },
        },
        mounted() {
            this.chart = new Chart(this.$refs.chart, {
                type: this.chartType,
                data: this.chartData,
                options: this.chartOptions,
                plugins: this.chartPlugins,
            });
        },
        beforeUnmount() {
            if (this.chart) {
                this.chart.destroy();
            }
        },
    };
</script>
