export default function gridSpan(width, fallback = "full") {
    return {
        "1/12": "lg:col-span-1",
        "1/6": "lg:col-span-2",
        "1/4": "lg:col-span-3",
        "1/3": "lg:col-span-4",
        "1/2": "lg:col-span-6",
        "2/3": "lg:col-span-8",
        "3/4": "lg:col-span-9",
        full: "lg:col-span-12",
    }[width || fallback];
}
