<template>
    <div
        v-if="hasContent"
        class="relative break-normal"
    >
        <div
            v-if="shouldShow || expanded"
            class="prose prose-sm dark:prose-invert max-w-none"
        >
            <div
                v-if="escapeHtml"
                v-html="content"
            />
            <div
                v-else
                v-text="content"
            />
        </div>

        <div
            v-else-if="!escapeHtml"
            class="prose prose-sm dark:prose-invert whitespace-nowrap overflow-hidden text-ellipsis"
            v-text="content"
        />

        <div
            v-show="!shouldShow"
            class="prose prose-sm absolute text-nowrap opacity-0 pointer-events-none"
            v-text="content"
            ref="expanded"
        />

        <button
            type="button"
            v-if="!shouldShow && hasMore"
            @click="toggle"
            class="link-default"
            aria-role="button"
            tabindex="0"
        >
            {{ showHideLabel }}
        </button>
    </div>
    <div v-else>{{ __("&mdash;") }}</div>
</template>

<script>
    export default {
        props: {
            shouldShow: {
                type: Boolean,
                default: false,
            },
            content: {
                type: String,
            },
            escapeHtml: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            expanded: false,
            expandedWidth: null,
            containerWidth: null,
        }),

        methods: {
            toggle() {
                this.expanded = !this.expanded;
            },

            handleResize() {
                this.containerWidth = this.$el.clientWidth;
            },
        },

        computed: {
            hasContent() {
                return this.content !== "" && this.content !== null;
            },

            hasMore() {
                return this.expandedWidth > this.containerWidth;
            },

            showHideLabel() {
                return !this.expanded
                    ? this.__("Show More")
                    : this.__("Show Less");
            },
        },

        mounted() {
            this.expandedWidth = this.$refs.expanded?.scrollWidth;
            this.containerWidth = this.$el.clientWidth;

            document.addEventListener("resize", this.handleResize);
        },

        beforeUnmount() {
            document.removeEventListener("resize", this.handleResize);
        },
    };
</script>
