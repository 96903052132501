import { App } from "../vue/inertia.js";

export class CustomerApp extends App {
    pagesGlob() {
        return import.meta.glob("./pages/**/*.vue");
    }
}

export function createInertiaApp(config) {
    return new CustomerApp(config);
}
