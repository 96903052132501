<template>
    <table-item :field="field">
        <template #value>
            <abbr
                v-if="!this.field.usesCustomizedDisplay"
                :title="formattedDatetime"
            >
                {{ formattedDate }}
            </abbr>
            <span v-else>{{ field.displayedAs }}</span>
        </template>
    </table-item>
</template>

<script>
    export default {
        props: ["field"],
        computed: {
            formattedDate() {
                return dayjs(this.field.value).format(
                    App.config("dayjs.formats.date"),
                );
            },
            formattedDatetime() {
                return dayjs(this.field.value).format(
                    App.config("dayjs.formats.datetime"),
                );
            },
        },
    };
</script>
