<template>
    <popper>
        <template #trigger="{ popper }">
            <v-button
                variant="outline"
                severity="light"
                padding="tight"
                icon="mdi-camera-iris"
                trailing-icon="mdi-chevron-down"
                :aria-label="__('Lens Dropdown')"
                @click="popper.toggle()"
            />
        </template>

        <template #content>
            <dropdown-menu width="auto">
                <dropdown-menu-item
                    v-for="lens in lenses"
                    :key="lens.uriKey"
                    :href="
                        lens.path ||
                        $url(`/resources/${resourceName}/lens/${lens.uriKey}`)
                    "
                    :as="lens.path ? 'a' : 'link'"
                >
                    {{ lens.name }}
                </dropdown-menu-item>
            </dropdown-menu>
        </template>
    </popper>
</template>

<script>
    import DropdownMenu from "@nova/components/Dropdowns/DropdownMenu.vue";
    import DropdownMenuItem from "@nova/components/Dropdowns/DropdownMenuItem.vue";
    import Popper from "@nova/components/Popper.vue";
    import VButton from "@nova/components/Buttons/VButton.vue";
    import { Link } from "@inertiajs/vue3";

    export default {
        components: {
            DropdownMenu,
            DropdownMenuItem,
            Link,
            Popper,
            VButton,
        },
        props: ["resourceName", "lenses"],
    };
</script>
