<script>
    import filled from "@nova/utils/filled";
    import get from "lodash/get";
    import { useClipboard } from "@vueuse/core";

    export default {
        /**
         * Note: Fields may have additional properties depending on type.
         *
         * @property {Object}   field
         * @property {String}   field.component
         * @property {String}   field.attribute
         * @property {String}   field.validationKey
         * @property {String}   field.name
         * @property {String=}  field.helpText
         * @property {String=}  field.placeholder
         * @property {Boolean=} field.readonly
         * @property {Boolean=} field.required
         * @property {String=}  field.textAlign
         * @property {Boolean=} field.stacked
         * @property {Boolean=} field.copyable
         * @property {Boolean=} field.usesCustomizedDisplay
         * @property {Boolean=} field.displayedAs
         * @property {Boolean=} field.asHtml
         * @property {Object=}  field.extraAttributes
         * @property {Boolean=} field.searchable
         * @property {Boolean=} field.nullable
         * @property {*}        field.value
         */
        props: {
            field: {
                type: Object,
                required: true,
            },
        },

        methods: {
            copy() {
                useClipboard({
                    source: this.fieldValue,
                    legacy: true,
                }).copy(this.fieldValue);
            },
        },

        computed: {
            usesCustomizedDisplay() {
                return (
                    this.field.usesCustomizedDisplay &&
                    filled(this.field.displayedAs)
                );
            },

            fieldHasValue() {
                return filled(this.field.value);
            },

            fieldValue() {
                if (!this.usesCustomizedDisplay && !this.fieldHasValue) {
                    return null;
                }

                if (this.usesCustomizedDisplay) {
                    return String(this.field.displayedAs);
                }

                return String(this.field.value);
            },

            isReadonly() {
                return Boolean(
                    this.field.readonly ||
                        get(this.field, "extraAttributes.readonly"),
                );
            },
        },
    };
</script>
