<template>
    <div class="mx-4 grid grid-cols-12 gap-4">
        <card-wrapper
            v-for="(card, i) in cards"
            :key="i"
            :card="card"
        />
    </div>
</template>

<script>
    import CardWrapper from "@nova/components/CardWrapper.vue";

    export default {
        components: {
            CardWrapper,
        },
        props: {
            /**
             * @property {Object[]}      cards
             * @property {string="card"} cards[].component
             * @property {string=}       cards[].width ("1/4"|"1/3"|"1/2"|"2/3"|"3/4"|"full")
             * @property {string=}       cards[].height ("sm"|"md"|"lg")
             */
            cards: Array,
        },
    };
</script>
