import registerComponents from "../utils/registerComponents";

function registerGroups(app, options, groups) {
    groups.forEach(([prefix, files]) => {
        registerComponents(app, files, options, prefix);
    });
}

export default {
    install(app, options) {
        registerGroups(app, options, [
            // Just about everything can be lazy loaded. The exception
            // to this are components that appear in high volumes,
            // which include index fields and action buttons.

            ["", import.meta.glob("../components/**/*.vue", { eager: true })],
            ["", import.meta.glob("../layouts/**/*.vue", { eager: true })],
            [
                "Index",
                import.meta.glob("../fields/Index/*.vue", { eager: true }),
            ],
            [
                "Detail",
                import.meta.glob("../fields/Detail/*.vue", { eager: false }),
            ],
            [
                "Form",
                import.meta.glob("../fields/Form/*.vue", { eager: false }),
            ],
            [
                "Filter",
                import.meta.glob("../fields/Filter/*.vue", { eager: false }),
            ],
        ]);
    },
};
