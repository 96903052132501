<template>
    <div class="h-full flex items-start justify-center">
        <div class="relative w-full">
            <!-- Remove Button -->
            <RemoveButton
                v-if="removable"
                class="absolute z-20 top-[-10px] right-[-9px]"
                @click.stop="handleRemoveClick"
                v-tooltip="__('Remove')"
                :dusk="$attrs.dusk"
            />

            <div
                class="bg-gray-50 dark:bg-gray-700 relative aspect-square flex items-center justify-center border-2 border-gray-200 dark:border-gray-700 overflow-hidden rounded-lg"
            >
                <!-- Upload Overlay -->
                <div
                    v-if="file.processing"
                    class="absolute inset-0 flex items-center justify-center"
                >
                    <ProgressBar
                        :title="uploadingLabel"
                        class="mx-4"
                        color="bg-green-500"
                        :value="uploadingPercentage"
                    />
                    <div class="bg-primary-900 opacity-5 absolute inset-0" />
                </div>

                <!-- Image Preview -->
                <img
                    v-if="isImage"
                    :src="previewUrl"
                    class="aspect-square object-scale-down"
                />
                <div v-else>
                    <div
                        class="rounded bg-gray-200 border-2 border-gray-200 p-4"
                    >
                        <Icon
                            name="heroicon-c-document-text"
                            width="50"
                            height="50"
                        />
                    </div>
                </div>
            </div>

            <!-- File Information -->
            <p class="font-semibold text-xs mt-1">{{ file.name }}</p>
        </div>
    </div>
</template>

<script setup>
    import { useFilePreviews } from "@nova/composables/useFilePreviews";
    import { computed, toRef } from "vue";
    import Icon from "@nova/components/Icon.vue";
    import ProgressBar from "@nova/components/ProgressBar.vue";

    const emit = defineEmits(["removed"]);
    const props = defineProps({
        file: { type: Object },
        removable: { type: Boolean, default: true },
    });

    const uploadingLabel = computed(() => {
        if (props.file.processing) {
            return __("Uploading") + " (" + props.file.progress + "%)";
        }

        return props.file.name;
    });

    const uploadingPercentage = computed(() => {
        if (props.file.processing) {
            return props.file.progress;
        }

        return 100;
    });

    const { previewUrl, isImage } = useFilePreviews(toRef(props, "file"));

    const handleRemoveClick = () => emit("removed");
</script>

<script>
    export default {
        inheritAttrs: false,
    };
</script>
