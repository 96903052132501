<template>
    <teleport :to="to">
        <component
            v-for="(filter, index) in filters"
            :key="`${filter.class}-${index}`"
            :is="filter.component"
            :filter-key="filter.class"
            :lens="lens"
            :resource-name="resourceName"
            @change="$emit('filter-changed', $event)"
        />
    </teleport>
</template>

<script>
    import { computed } from "vue";

    export default {
        emits: ["filter-changed"],

        props: {
            to: {
                type: String,
                required: true,
            },

            filters: {
                type: Array,
                required: true,
            },

            lens: {
                type: String,
                default: null,
            },

            stacked: {
                type: Boolean,
                default: false,
            },

            width: {
                type: String,
                default: "full",
            },
        },

        inject: ["resourceName"],

        provide() {
            return {
                stacked: computed(() => this.stacked),
                defaultWidth: computed(() => this.width),
            };
        },
    };
</script>
