<script>
    import find from "lodash/find";
    import some from "lodash/some";
    import { mapActions, mapGetters } from "vuex";
    import { mapProps } from "@nova/utils";
    import { computed } from "vue";

    export default {
        props: mapProps([
            "resourceName",
            "viaResource",
            "viaResourceId",
            "viaRelationship",
            "relationshipType",
            "shouldEnableShortcut",
            "shouldOverrideMeta",
        ]),

        provide() {
            return {
                queryStringParams: computed(() => this.queryStringParams),
                resourceInformation: computed(() => this.resourceInformation),
            };
        },

        methods: {
            ...mapActions(["syncQueryString", "updateQueryString"]),

            autoStackPanels(panels) {
                return panels.map((panel) => this.autoStackPanel(panel));
            },

            autoStackPanel(panel) {
                const stacked =
                    panel.stacked !== undefined
                        ? panel.stacked
                        : some(panel.fields, (f) => f.width || f.stacked);

                panel.stacked = stacked;

                panel.fields = panel.fields.map((field) => {
                    field.stacked = stacked;
                    return field;
                });

                return panel;
            },
        },

        computed: {
            ...mapGetters(["queryStringParams"]),

            resourceInformation() {
                return find(Nova.config("resources"), (resource) => {
                    return resource.uriKey === this.resourceName;
                });
            },

            viaResourceInformation() {
                if (!this.viaResource) {
                    return;
                }

                return find(Nova.config("resources"), (resource) => {
                    return resource.uriKey === this.viaResource;
                });
            },

            /**
             * Determine if the user is authorized to create the current resource.
             */
            authorizedToCreate() {
                if (
                    ["hasOneThrough", "hasManyThrough"].indexOf(
                        this.relationshipType,
                    ) >= 0
                ) {
                    return false;
                }

                return this.resourceInformation?.authorizedToCreate || false;
            },
        },

        async created() {
            this.syncQueryString();
        },
    };
</script>
