<template>
    <component
        :is="component"
        v-bind="defaultAttributes"
        class="flex items-center w-full text-left px-4 focus:outline-none rounded truncate whitespace-nowrap"
        :class="{
            'text-sm py-1.5 first:pt-2 last:pb-2': size === 'sm',
            'text-base py-2': size === 'lg',
            'hover:bg-black/5 dark:hover:bg-white/5': !disabled,
            'text-gray-500 cursor-default': disabled,
            'link-default': as === 'link' || as === 'a',
        }"
    >
        <slot />
    </component>
</template>

<script>
    import { Link } from "@inertiajs/vue3";

    export default {
        components: {
            Link,
        },

        props: {
            as: {
                type: String,
                default: "button",
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            size: {
                type: String,
                default: "sm",
                validator: (v) => ["sm", "lg"].includes(v),
            },
        },

        computed: {
            component() {
                return (
                    {
                        button: "button",
                        external: "a",
                        link: "Link",
                        "form-button": "FormButton",
                    }[this.as] || this.as
                );
            },

            defaultAttributes() {
                return {
                    ...this.$attrs,
                    ...{
                        disabled:
                            this.as === "button" && this.disabled === true
                                ? true
                                : null,
                        type: this.as === "button" ? "button" : null,
                    },
                };
            },
        },
    };
</script>
