<template>
    <div class="flex flex-col gap-2">
        <h4
            v-if="title"
            v-text="title"
            class="m-0 p-0"
        />
        <div
            class="flex-1 pb-4"
            v-show="shouldShowChart"
        >
            <canvas ref="chart"></canvas>
        </div>
        <div
            v-if="!shouldShowChart"
            class="flex flex-col items-center justify-center absolute inset-0 pointer-events-none"
            :style="{ color: colors.muted }"
        >
            <div>Insufficient Data!</div>
            <div
                class="text-xs"
                v-if="!sufficientStd"
            >
                &sigma; must be > 0
            </div>
            <div
                class="text-xs"
                v-if="!sufficientPopulation"
            >
                n must be <u>></u> 30
            </div>
        </div>

        <help-tooltips>
            <help-distribution-tooltip
                :mean="this.data.mean"
                :std="this.data.std"
                :population="this.data.population"
                :range="distributionRange"
                :format="this.format"
            />
            <help-range-tooltip :range="range" />
            <help-text-tooltip
                icon="mdi-radio-tower"
                title="Out of Control Signals"
                :text="helpText"
                :width="600"
            />
            <slot name="help" />
        </help-tooltips>
    </div>
</template>

<script>
    import BaseRangedChartMetric from "./BaseRangedChartMetric.vue";
    import HelpTooltips from "@nova/components/HelpTooltips.vue";
    import HelpDistributionTooltip from "@nova/components/HelpDistributionTooltip.vue";
    import HelpRangeTooltip from "@nova/components/HelpRangeTooltip.vue";

    export default {
        extends: BaseRangedChartMetric,
        components: {
            HelpTooltips,
            HelpDistributionTooltip,
            HelpRangeTooltip,
        },
        props: {
            /**
             * @property {Object=} range
             * @property {Number}  range.length
             * @property {String}  range.resolution ("Minute"|"Hour"|"Day"|"Week"|"Month")
             * @property {String}  range.unit ("minutes"|"hours"|"days"|"weeks"|"months")
             * @property {String}  range.start (Date Format "YYYY-MM-DDTHH:MM:SS-TT:TT")
             * @property {String}  range.end (Date Format "YYYY-MM-DDTHH:MM:SS-TT:TT")
             * @property {String}  range.timezone (e.g. "America/Chicago")
             */
            distributionRange: Object,
        },
        data: () => ({
            selected: null,
        }),
        methods: {
            getSignal(z, zi, zValues) {
                const abs = Math.abs(z);
                const sign = Math.sign(z);

                let sequence = [];

                let signal = this.controlPointSignals.find((signal) => {
                    if (abs < signal.std) {
                        return false;
                    }

                    if (zi - 1 < signal.of) {
                        return false;
                    }

                    sequence = [];

                    const start = zi - 1;
                    let end = start - signal.of + 1;
                    let has = 1;

                    for (let i = start; i > end && i > 0; i--) {
                        const s = zValues[i];

                        if (s === null) {
                            sequence.push(null);
                            end--;
                            continue;
                        }

                        if (Math.abs(s) < signal.std) {
                            sequence.push(false);
                            continue;
                        }

                        if (Math.sign(s) != sign) {
                            sequence.push(false);
                            continue;
                        }

                        sequence.push(true);
                        has += 1;
                    }

                    return has >= signal.has;
                });

                if (!signal) {
                    return null;
                }

                return {
                    ...signal,
                    sequence,
                };
            },
        },
        computed: {
            helpText() {
                return (
                    '<ul class="text-xs mb-0"><li>' +
                    this.controlPointSignals
                        .map((signal) => {
                            if (signal.of === 1) {
                                return "A single point outside of the control limits (above 3σ).";
                            }

                            const sequence =
                                signal.has === signal.of
                                    ? `A run of ${signal.of}`
                                    : `At least ${signal.has} of ${signal.of}`;

                            if (signal.std === 0) {
                                return `${sequence} points are on the same side of the centerline.`;
                            }

                            return `${sequence} points are farther than ${signal.std}σ on the same side of the centerline.`;
                        })
                        .join("</li><li>") +
                    "</li></ul>"
                );
            },
            chartType() {
                return "line";
            },
            controlPointSignals() {
                return [
                    { std: 3, has: 1, of: 1 },
                    { std: 2, has: 2, of: 3 },
                    { std: 1, has: 4, of: 5 },
                    { std: 0, has: 8, of: 8 },
                    { std: 0, has: 10, of: 11 },
                    { std: 0, has: 12, of: 14 },
                    { std: 0, has: 16, of: 20 },
                ];
            },
            chartData() {
                if (!this.data.mean || !this.data.trend) {
                    return {};
                }

                const mean = this.data.mean;
                const std = this.data.std;

                const values = Object.values(this.data.trend);
                const zValues = values.map((v) =>
                    [0, null].includes(v) ? null : (v - mean) / std,
                );

                const signals = zValues.map((z, i) =>
                    this.getSignal(z, i, zValues),
                );

                return {
                    labels: Object.keys(this.data.trend),
                    datasets: [
                        {
                            data: zValues,
                            values: values,
                            signals: signals,
                            fill: false,
                            borderColor: this.colors.primary,
                            pointRadius: 0,
                            pointHoverRadius: 3,
                            pointBackgroundColor: this.colors.primary,
                        },
                    ],
                };
            },
            chartOptions() {
                return {
                    interaction: {
                        mode: "index",
                        intersect: false,
                    },
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            display: false,
                        },
                        y: {
                            grid: {
                                tickColor: "white",
                            },
                            border: {
                                display: false,
                            },
                            ticks: {
                                callback: (value) => {
                                    const sigma = "σ"; // std symbol
                                    return value
                                        ? Math.abs(value) + sigma
                                        : value;
                                },
                            },
                            position: "right",
                            suggestedMin: -3,
                            suggestedMax: 3,
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            ...this.chartTooltip,
                            callbacks: {
                                label: (context) => {
                                    const i = context.dataIndex;
                                    const value = this.formatNumber(
                                        context.dataset.values[i],
                                        this.format,
                                    );

                                    this.selected = i;

                                    const sigma = "σ"; // std symbol
                                    const std = context.formattedValue + sigma;

                                    const formattedValue = this.suffix
                                        ? `${this.suffix}: ${value}`
                                        : value;

                                    return `${formattedValue} (${std})`;
                                },
                                footer: (contexts) => {
                                    const i = contexts[0].dataIndex;
                                    const dataset = contexts[0].dataset;

                                    const signal = dataset.signals[i];

                                    if (!signal) {
                                        return;
                                    }

                                    if (signal.of === 1) {
                                        return "Outside of control limits.";
                                    }

                                    const sequence =
                                        signal.has === signal.of
                                            ? `A run of ${signal.of}`
                                            : `At least ${signal.has} of ${signal.of}`;

                                    if (signal.std === 0) {
                                        return `${sequence} are on the same side\nof the centerline.`;
                                    }

                                    return `${sequence} are farther than ${signal.std}σ\nof the centerline.`;
                                },
                            },
                        },
                        controlPoints: {
                            signalColor: this.colors.secondary,
                            signalRadius: 5,
                            signalLineWidth: 2,
                            signals: this.controlPointSignals,
                            successiveColor: this.colors.decrease,
                            nonSuccessiveColor: this.colors.increase,
                        },
                    },
                };
            },
            chartPlugins() {
                return [
                    {
                        id: "controlPoints",
                        afterDatasetDraw: (chart, args, options) => {
                            const ctx = chart.ctx;
                            const dataset = chart.data?.datasets[0];
                            const data = dataset?.data || [];
                            const xAxis = chart.scales.x;
                            const yAxis = chart.scales.y;

                            data.forEach((v, i) => {
                                const x = xAxis.getPixelForValue(i);
                                const y = yAxis.getPixelForValue(v);
                                const r = options.signalRadius;

                                const signal = dataset.signals[i];

                                if (!signal) {
                                    return;
                                }

                                ctx.strokeStyle = options.signalColor;
                                ctx.lineWidth = options.signalLineWidth;
                                ctx.textAlign = "center";
                                ctx.beginPath();
                                ctx.arc(x, y, r, 0, 2 * Math.PI);
                                ctx.stroke();

                                if (this.selected !== i) {
                                    return;
                                }

                                ctx.fillStyle = ctx.strokeStyle;
                                ctx.textBaseline =
                                    Math.sign(v) >= 0 ? "bottom" : "top";

                                const ty =
                                    Math.sign(v) >= 0
                                        ? -(r + options.signalLineWidth)
                                        : r + options.signalLineWidth;

                                ctx.fillText(1, x, y + ty);

                                let offset = 0;

                                signal.sequence.forEach((flagged, j) => {
                                    if (flagged === null) {
                                        offset++;
                                        return;
                                    }

                                    const xv = i - j - 1;
                                    const yv = data[i - j - 1];

                                    const x = xAxis.getPixelForValue(xv);
                                    const y = yAxis.getPixelForValue(yv);

                                    ctx.strokeStyle = flagged
                                        ? options.successiveColor
                                        : options.nonSuccessiveColor;

                                    ctx.beginPath();
                                    ctx.arc(x, y, r, 0, 2 * Math.PI);
                                    ctx.stroke();

                                    ctx.fillStyle = ctx.strokeStyle;
                                    ctx.textBaseline =
                                        Math.sign(yv) >= 0 ? "bottom" : "top";

                                    const ty =
                                        Math.sign(yv) >= 0
                                            ? -(r + options.signalLineWidth)
                                            : r + options.signalLineWidth;

                                    ctx.fillText(j + 2 - offset, x, y + ty);
                                });
                            });
                        },
                    },
                ];
            },
            shouldShowChart() {
                return this.sufficientStd && this.sufficientPopulation;
            },
            sufficientStd() {
                return this.data.std > 0;
            },
            sufficientPopulation() {
                return this.data.population >= 30;
            },
        },
    };
</script>
