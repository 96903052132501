<template>
    <nova-layout
        :title="title"
        :section="section"
    >
        <div class="flex md:flex-row flex-col mx-4 gap-4">
            <sidebar v-if="Object.keys(examples).length > 1">
                <ul class="list-none m-0 p-0">
                    <li
                        v-for="(example, name) in examples"
                        class="hover:bg-gray-100 p-2 border-l-2 cursor-pointer"
                        :class="{
                            'border-blue-500 text-blue-500 bg-blue-50':
                                selectedComponent === name,
                        }"
                        v-text="name"
                        @click="onComponentSelected(name)"
                    />
                </ul>
            </sidebar>
            <div class="flex-1">
                <div class="grid lg:grid-cols-6 gap-4">
                    <div class="lg:col-span-5 flex flex-col gap-4">
                        <section
                            data-testid="macros"
                            class="flex flex-col gap-2"
                            v-if="
                                example.modes ||
                                (example.presets &&
                                    Object.keys(example.presets).length > 1)
                            "
                        >
                            <div
                                v-if="example.modes"
                                data-testid="modes"
                                class="grid grid-cols-12 gap-4 items-center"
                            >
                                <div class="text-center text-xs font-semibold">
                                    Mode
                                </div>
                                <div class="grid grid-cols-6 gap-4 col-span-11">
                                    <v-button
                                        v-for="(enabled, name) in example.modes"
                                        :key="name"
                                        variant="outline"
                                        size="sm"
                                        class="bg-white"
                                        :severity="
                                            name === selectedMode
                                                ? 'primary'
                                                : 'white'
                                        "
                                        :disabled="!enabled"
                                        @click="onModeSelected(name)"
                                    >
                                        {{ name }}
                                    </v-button>
                                </div>
                            </div>
                            <div
                                v-if="
                                    example.presets &&
                                    Object.keys(example.presets).length > 1
                                "
                                data-testid="presets"
                                class="grid grid-cols-12 gap-4 items-center"
                            >
                                <div class="text-center text-xs font-semibold">
                                    Preset
                                </div>
                                <div class="grid grid-cols-6 gap-4 col-span-11">
                                    <v-button
                                        v-for="(
                                            preset, name
                                        ) in example.presets"
                                        :key="name"
                                        variant="outline"
                                        size="sm"
                                        class="bg-white"
                                        :severity="
                                            name === selectedPreset
                                                ? 'primary'
                                                : 'white'
                                        "
                                        @click="onPresetSelected(name)"
                                    >
                                        {{ name }}
                                    </v-button>
                                </div>
                            </div>
                        </section>

                        <div>
                            <card>
                                <section
                                    data-testid="component"
                                    class="flex flex-col gap-2"
                                >
                                    <div>
                                        <slot
                                            name="component"
                                            :example="example"
                                            :mode="selectedMode"
                                            :attrs="attrs"
                                        >
                                            <component
                                                :is="example.component"
                                                v-bind="attrs"
                                            />
                                        </slot>
                                    </div>
                                </section>
                            </card>
                        </div>

                        <slot name="extraContent" />

                        <div v-if="hasComponentOptions">
                            <h2>Component Options</h2>
                            <card class="flex flex-col gap-4 divide-y">
                                <div class="grid grid-cols-1">
                                    <section
                                        v-for="(
                                            group, name
                                        ) in componentOptions"
                                        :key="name"
                                        class="space-y-1 pt-2 first:pt-0 pb-2 border-b last:border-b-0"
                                    >
                                        <h2 class="m-0 p-0">
                                            {{ name }}
                                        </h2>
                                        <template
                                            v-if="group.modes?.length > 1"
                                        >
                                            <div class="flex gap-2">
                                                <span
                                                    v-for="(
                                                        mode, i
                                                    ) in group.modes"
                                                    :key="i"
                                                    v-text="mode"
                                                    class="text-xxs uppercase bg-gray-500 text-white font-bold px-2 py-1 rounded"
                                                />
                                            </div>
                                        </template>
                                        <field-grid stacked>
                                            <component
                                                v-for="(
                                                    field, i
                                                ) in group.fields"
                                                :key="`${example.component}.${name}.${i}`"
                                                :is="`form-${field.component}`"
                                                :field="{
                                                    ...field,
                                                    width: field.width || '1/6',
                                                    value: attrs[
                                                        field.attribute
                                                    ],
                                                }"
                                                v-model="attrs[field.attribute]"
                                            />
                                        </field-grid>
                                    </section>
                                </div>
                            </card>
                        </div>
                    </div>
                    <div>
                        <card class="flex flex-col gap-4 divide-y">
                            <template
                                v-for="(group, key) in options"
                                :key="key"
                            >
                                <section
                                    v-if="
                                        !group.modes ||
                                        group.modes.includes(selectedMode)
                                    "
                                    class="pt-2 first:pt-0 flex flex-col gap-2"
                                >
                                    <h2 class="m-0 p-0">
                                        {{ optionGroupName(group, key) }}
                                    </h2>
                                    <template v-if="group.modes?.length > 1">
                                        <div class="flex gap-2">
                                            <span
                                                v-for="(mode, i) in group.modes"
                                                :key="i"
                                                v-text="mode"
                                                class="text-xxs uppercase bg-gray-500 text-white font-bold px-2 py-1 rounded"
                                            />
                                        </div>
                                    </template>
                                    <field-grid stacked>
                                        <component
                                            v-for="(
                                                field, i
                                            ) in optionGroupFields(group)"
                                            :key="`${field.name}.${i}`"
                                            :is="`form-${field.component}`"
                                            :field="{
                                                ...field,
                                                value: attrs[field.attribute],
                                            }"
                                            v-model="attrs[field.attribute]"
                                        />
                                    </field-grid>
                                </section>
                            </template>
                        </card>
                    </div>
                </div>
            </div>
        </div>
    </nova-layout>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                required: true,
            },
            dataset: {
                type: Object,
                required: true,
            },
            section: {
                type: String,
                default: "UI Components",
            },
        },
        data: () => ({
            selectedComponent: null,
            selectedMode: null,
            selectedPreset: null,
            example: null,
            attrs: {},
        }),
        methods: {
            onModeSelected(name) {
                this.selectedMode = name;
            },
            onComponentSelected(name) {
                this.selectedComponent = name;
                this.example = this.examples[name];

                const preset = this.example.presets
                    ? Object.keys(this.example.presets)[0]
                    : "Default";

                this.onPresetSelected(preset);

                const mode =
                    this.example.defaultMode ||
                    this.dataset.defaultMode ||
                    _(this.example.modes)
                        .pickBy((v) => v)
                        .keys()
                        .first();
                this.onModeSelected(mode);
            },
            onPresetSelected(name) {
                const example = this.example;

                this.selectedPreset = name;
                this.attrs = {};

                const preset = example.presets ? example.presets[name] : {};

                Object.values(this.example.options || {}).forEach((group) => {
                    group.fields?.forEach((field) => {
                        this.attrs[field.attribute] = field.value;
                    });
                });

                Object.values(this.options).forEach((group) => {
                    const fields = this.optionGroupFields(group);

                    fields.forEach((field) => {
                        this.attrs[field.attribute] = field.value;
                    });
                });

                Object.entries(preset).forEach(([key, value]) => {
                    this.attrs[key] = value;
                });
            },
            optionGroupName(group, key) {
                return group.name ? group.name(this.selectedMode) : key;
            },
            optionGroupFields(group) {
                return Array.isArray(group.fields)
                    ? group.fields
                    : group.fields(this.selectedMode);
            },
        },
        computed: {
            examples() {
                return this.dataset.components;
            },
            options() {
                return this.dataset.options;
            },
            componentOptions() {
                return _.pickBy(this.example.options, (group) => {
                    return (
                        !group.modes || group.modes.includes(this.selectedMode)
                    );
                });
            },
            hasComponentOptions() {
                return Object.keys(this.componentOptions).length > 0;
            },
        },
        created() {
            this.onComponentSelected(Object.keys(this.examples)[0]);
        },
    };
</script>
