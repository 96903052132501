<template>
    <span
        class="badge"
        :class="{
            'badge-circle': circle,
            [extraClasses]: true,
        }"
    >
        <slot name="icon" />
        <slot>
            {{ label }}
        </slot>
    </span>
</template>

<script>
    export default {
        props: {
            label: {
                type: [Boolean, String, Number],
                required: false,
            },

            circle: {
                type: Boolean,
                default: false,
            },

            extraClasses: {
                type: [Array, String],
                required: false,
                default: "",
            },
        },
    };
</script>
