<template>
    <popper
        placement="bottom-start"
        dusk="select-all-dropdown"
    >
        <template #trigger="{ popper }">
            <v-button
                :variant="selectedResourcesCount > 0 ? 'solid' : 'ghost'"
                :severity="selectedResourcesCount > 0 ? 'info' : 'light'"
                padding="tight"
                trailing-icon="mdi-chevron-down"
                class="-ml-2"
                dusk="select-all-dropdown-trigger"
                @click="popper.toggle()"
            >
                <checkbox
                    :aria-label="__('Select this page')"
                    :indeterminate="selectAllIndeterminate"
                    :model-value="selectAllAndSelectAllMatchingChecked"
                    class="pointer-events-none"
                    dusk="select-all-indicator"
                    tabindex="-1"
                />

                <template v-if="selectedResourcesCount > 0">
                    <span class="pl-2 pr-1">{{
                        __(":amount selected", {
                            amount: selectAllMatchingChecked
                                ? allMatchingResourceCount || "ALL"
                                : selectedResourcesCount,
                            label: pluralize(
                                "resources",
                                selectedResourcesCount,
                            ),
                        })
                    }}</span>

                    <v-button
                        @click.stop="$emit('deselect')"
                        variant="link"
                        icon="mdi-close-circle"
                        size="sm"
                        padding="tight"
                        severity="light"
                        class="-ml-1 -mr-2"
                        :aria-label="__('Deselect All')"
                        dusk="deselect-all-button"
                    />
                </template>
            </v-button>
        </template>
        <template #content="{ popper }">
            <dropdown-menu
                direction="ltr"
                width="250"
            >
                <dropdown-menu-item>
                    <checkbox
                        @change="
                            popper.close();
                            $emit('toggle-select-all');
                        "
                        :model-value="selectAllChecked"
                        class="w-full"
                        dusk="select-all-button"
                    >
                        <span>
                            {{ __("Select this page") }}
                        </span>
                        <badge
                            class="bg-blue-500 text-white ml-auto"
                            circle
                        >
                            {{ currentPageCount }}
                        </badge>
                    </checkbox>
                </dropdown-menu-item>

                <dropdown-menu-item>
                    <checkbox
                        @change="
                            popper.close();
                            $emit('toggle-select-all-matching');
                        "
                        :model-value="selectAllMatchingChecked"
                        class="w-full"
                        dusk="select-all-matching-button"
                    >
                        <span>
                            {{ __("Select all matching") }}
                        </span>
                        <badge
                            class="bg-blue-500 text-white ml-auto"
                            dusk="select-all-matching-count"
                            circle
                        >
                            {{ allMatchingResourceCount || "ALL" }}
                        </badge>
                    </checkbox>
                </dropdown-menu-item>
            </dropdown-menu>
        </template>
    </popper>
</template>

<script>
    import pluralize from "pluralize";
    import Popper from "@nova/components/Popper.vue";
    import VButton from "@nova/components/Buttons/VButton.vue";
    import Checkbox from "@nova/components/Inputs/Checkbox.vue";
    import DropdownMenu from "@nova/components/Dropdowns/DropdownMenu.vue";
    import DropdownMenuItem from "@nova/components/Dropdowns/DropdownMenuItem.vue";

    export default {
        emits: ["toggle-select-all", "toggle-select-all-matching", "deselect"],

        components: {
            Popper,
            VButton,
            Checkbox,
            DropdownMenu,
            DropdownMenuItem,
        },

        props: {
            currentPageCount: { type: Number, default: 0 },
            allMatchingResourceCount: { type: Number, default: 0 },
        },

        inject: [
            "selectedResourcesCount",
            "selectAllChecked",
            "selectAllMatchingChecked",
            "selectAllAndSelectAllMatchingChecked",
            "selectAllOrSelectAllMatchingChecked",
            "selectAllIndeterminate",
        ],

        methods: {
            pluralize,
        },
    };
</script>
