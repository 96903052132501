<template>
    <div
        class="flex flex-col-reverse lg:flex-row lg:items-center gap-2 lg:divide-x lg:divide-gray-300"
    >
        <div
            v-if="runActions.length > 0"
            class="ml-auto"
        >
            <div class="flex flex-wrap items-center gap-2">
                <action-buttons
                    v-if="runActions.length > 0"
                    @actionExecuted="$emit('actionExecuted', $event)"
                    :resource-name="resourceName"
                    :via-resource="viaResource"
                    :via-resource-id="viaResourceId"
                    :via-relationship="viaRelationship"
                    :actions="runActions"
                    :selected-resources="[resource]"
                />
            </div>
        </div>
        <button-group class="lg:pl-2 first:pl-0">
            <action-buttons
                @actionExecuted="$emit('actionExecuted', $event)"
                :resource-name="resourceName"
                :via-resource="viaResource"
                :via-resource-id="viaResourceId"
                :via-relationship="viaRelationship"
                :actions="linkActions"
                :selected-resources="[resource]"
            >
                <!-- Index Link -->
                <v-button
                    :as="resourceInformation?.routeToIndex ? 'a' : 'link'"
                    padding="tight"
                    size="sm"
                    icon="mdi-table"
                    severity="muted"
                    :href="
                        resourceInformation?.routeToIndex ||
                        $url(`/resources/${resourceName}`)
                    "
                    :label="__('Browse') + ' ' + resourceInformation?.label"
                    dusk="index-resource-button"
                    tabindex="1"
                    @click.stop
                />

                <!-- Edit Link -->
                <v-button
                    v-if="resource.authorizedToUpdate"
                    :as="resource.routeToUpdate ? 'a' : 'link'"
                    padding="tight"
                    size="sm"
                    icon="mdi-pencil"
                    severity="muted"
                    :href="
                        resource.routeToUpdate ||
                        $url(`/resources/${resourceName}/${resourceId}/edit`)
                    "
                    :label="
                        __('Edit') + ' ' + resourceInformation?.singularLabel
                    "
                    dusk="edit-resource-button"
                    tabindex="1"
                    @click.stop
                />
            </action-buttons>
        </button-group>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import { mapProps } from "@nova/utils";
    import ActionButtons from "@nova/components/ActionButtons.vue";
    import VButton from "@nova/components/Buttons/VButton.vue";

    export default {
        components: {
            ActionButtons,
            VButton,
        },

        emits: ["actionExecuted", "resource-deleted", "resource-restored"],

        inject: ["resourceInformation"],

        props: {
            resource: { type: Object },
            actions: { type: Array },
            viaManyToMany: { type: Boolean },

            ...mapProps([
                "resourceId",
                "resourceName",
                "viaResource",
                "viaResourceId",
                "viaRelationship",
            ]),
        },

        data: () => ({
            deleteModalOpen: false,
            restoreModalOpen: false,
            forceDeleteModalOpen: false,
        }),

        methods: {
            ...mapActions(["startImpersonating"]),

            /**
             * Show the confirmation modal for deleting or detaching a resource
             */
            async confirmDelete() {
                this.deleteResources([this.resource], (response) => {
                    Nova.success(
                        this.__("The :resource was deleted!", {
                            resource:
                                this.resourceInformation.singularLabel.toLowerCase(),
                        }),
                    );

                    if (response && response.data && response.data.redirect) {
                        Nova.visit(response.data.redirect);
                        return;
                    }

                    if (!this.resource.softDeletes) {
                        Nova.visit(`/resources/${this.resourceName}`);
                        return;
                    }

                    this.closeDeleteModal();
                    this.$emit("resource-deleted");
                });
            },

            openDeleteModal() {
                this.deleteModalOpen = true;
            },

            closeDeleteModal() {
                this.deleteModalOpen = false;
            },

            /**
             * Show the confirmation modal for restoring a resource
             */
            async confirmRestore() {
                this.restoreResources([this.resource], () => {
                    Nova.success(
                        this.__("The :resource was restored!", {
                            resource:
                                this.resourceInformation.singularLabel.toLowerCase(),
                        }),
                    );

                    this.closeRestoreModal();
                    this.$emit("resource-restored");
                });
            },

            openRestoreModal() {
                this.restoreModalOpen = true;
            },

            closeRestoreModal() {
                this.restoreModalOpen = false;
            },

            /**
             * Show the confirmation modal for force deleting
             */
            async confirmForceDelete() {
                this.forceDeleteResources([this.resource], (response) => {
                    Nova.success(
                        this.__("The :resource was deleted!", {
                            resource:
                                this.resourceInformation.singularLabel.toLowerCase(),
                        }),
                    );

                    if (response && response.data && response.data.redirect) {
                        Nova.visit(response.data.redirect);
                        return;
                    }

                    Nova.visit(`/resources/${this.resourceName}`);
                });
            },

            /**
             * Open the force delete modal
             */
            openForceDeleteModal() {
                this.forceDeleteModalOpen = true;
            },

            /**
             * Close the force delete modal
             */
            closeForceDeleteModal() {
                this.forceDeleteModalOpen = false;
            },
        },

        computed: {
            ...mapGetters(["currentUser"]),

            runActions() {
                return this.actions.filter((a) => !a.link);
            },

            linkActions() {
                return this.actions.filter((a) => a.link);
            },
        },
    };
</script>
