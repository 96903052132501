<template>
    <button
        type="button"
        @click.prevent="handleClick"
        class="cursor-pointer inline-flex items-center focus:outline-none"
        :dusk="'sort-' + uriKey"
        :aria-sort="ariaSort"
    >
        <slot />

        <svg
            class="ml-2 shrink-0"
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="14"
            viewBox="0 0 8 14"
        >
            <path
                :class="descClass"
                d="M1.70710678 4.70710678c-.39052429.39052429-1.02368927.39052429-1.41421356 0-.3905243-.39052429-.3905243-1.02368927 0-1.41421356l3-3c.39052429-.3905243 1.02368927-.3905243 1.41421356 0l3 3c.39052429.39052429.39052429 1.02368927 0 1.41421356-.39052429.39052429-1.02368927.39052429-1.41421356 0L4 2.41421356 1.70710678 4.70710678z"
            />
            <path
                :class="ascClass"
                d="M6.29289322 9.29289322c.39052429-.39052429 1.02368927-.39052429 1.41421356 0 .39052429.39052429.39052429 1.02368928 0 1.41421358l-3 3c-.39052429.3905243-1.02368927.3905243-1.41421356 0l-3-3c-.3905243-.3905243-.3905243-1.02368929 0-1.41421358.3905243-.39052429 1.02368927-.39052429 1.41421356 0L4 11.5857864l2.29289322-2.29289318z"
            />
        </svg>
    </button>
</template>

<script>
    export default {
        emits: ["sort", "reset"],

        inject: {
            queryStringParams: { from: "queryStringParams", default: {} },
            orderByParameter: { from: "orderByParameter", default: "showcase" },
            orderByDirectionParameter: {
                from: "orderByDirectionParameter",
                default: "showcase",
            },
            showcase: { from: "showcase", default: false },
        },

        props: {
            resourceName: String,
            uriKey: String,
        },

        data: () => ({
            showcaseDirection: null,
        }),

        methods: {
            handleClick() {
                if (this.showcase) {
                    return this.handleShowcaseClick();
                }

                if (this.isSorted && this.isDescDirection) {
                    this.$emit("reset");
                } else {
                    this.$emit("sort", {
                        key: this.uriKey,
                        direction: this.direction,
                    });
                }
            },

            handleShowcaseClick() {
                if (!this.showcaseDirection) {
                    this.showcaseDirection = "asc";
                } else if (this.showcaseDirection == "asc") {
                    this.showcaseDirection = "desc";
                } else {
                    this.showcaseDirection = null;
                }
            },
        },

        computed: {
            isDescDirection() {
                return this.direction == "desc";
            },

            isAscDirection() {
                return this.direction == "asc";
            },

            ascClass() {
                if (this.isSorted && this.isDescDirection) {
                    return "fill-white";
                }

                return "fill-gray-500";
            },

            descClass() {
                if (this.isSorted && this.isAscDirection) {
                    return "fill-white";
                }

                return "fill-gray-500";
            },

            isSorted() {
                return (
                    this.sortColumn == this.uriKey &&
                    ["asc", "desc"].includes(this.direction)
                );
            },

            sortKey() {
                return this.orderByParameter;
            },

            sortColumn() {
                return this.showcase
                    ? "showcase"
                    : this.queryStringParams[this.sortKey];
            },

            directionKey() {
                return this.orderByDirectionParameter;
            },

            direction() {
                return this.showcase
                    ? this.showcaseDirection
                    : this.queryStringParams[this.directionKey];
            },

            notSorted() {
                return !!!this.isSorted;
            },

            ariaSort() {
                if (this.isDescDirection) {
                    return "descending";
                } else if (this.isAscDirection) {
                    return "ascending";
                }

                return "none";
            },
        },
    };
</script>
