import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isoWeek from "dayjs/plugin/isoWeek";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjsBusinessDays from "dayjs-business-time";
import objectSupport from "dayjs/plugin/objectSupport";
import holidays from "../../data/holidays.json";

dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isoWeek);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(dayjsBusinessDays);
dayjs.extend(objectSupport);
dayjs.setHolidays(holidays);

export default dayjs;
