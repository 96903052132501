<template>
    <action-dropdown
        :resource="resource"
        :actions="actions"
        :via-resource="viaResource"
        :via-resource-id="viaResourceId"
        :via-relationship="viaRelationship"
        :resource-name="resourceName"
        @actionExecuted="$emit('actionExecuted', $event)"
        :selected-resources="[resource.id.value]"
        :show-headings="true"
    >
        <template #trigger>
            <v-button
                variant="ghost"
                padding="tight"
                size="sm"
                icon="mdi-dots-horizontal"
                :dusk="`${resource.id.value}-control-selector`"
            />
        </template>

        <template #menu>
            <!-- @todo: Preview Resource Link -->
            <dropdown-menu-item
                v-if="
                    resource.authorizedToView &&
                    resource.previewHasFields &&
                    false
                "
                :dusk="`${resource.id.value}-preview-button`"
                as="button"
                @click.prevent="$emit('show-preview')"
                :title="__('Preview')"
            >
                {{ __("Preview") }}
            </dropdown-menu-item>

            <!-- @todo: Replicate Resource Link -->
            <dropdown-menu-item
                v-if="resource.authorizedToReplicate && false"
                :dusk="`${resource.id.value}-replicate-button`"
                as="external"
                :href="
                    $url(
                        `/resources/${resourceName}/${resource.id.value}/replicate`,
                        {
                            viaResource,
                            viaResourceId,
                            viaRelationship,
                        },
                    )
                "
                :title="__('Replicate')"
            >
                {{ __("Replicate") }}
            </dropdown-menu-item>

            <!-- @todo: Impersonate Resource Button -->
            <dropdown-menu-item
                as="button"
                v-if="
                    currentUser.canImpersonate &&
                    resource.authorizedToImpersonate &&
                    false
                "
                :dusk="`${resource.id.value}-impersonate-button`"
                @click.prevent="
                    startImpersonating({
                        resource: resourceName,
                        resourceId: resource.id.value,
                    })
                "
                :title="__('Impersonate')"
            >
                {{ __("Impersonate") }}
            </dropdown-menu-item>
        </template>
    </action-dropdown>
</template>

<script>
    import { mapProps } from "@nova/utils";
    import { mapGetters, mapActions } from "vuex";
    import ActionDropdown from "@nova/components/Dropdowns/ActionDropdown.vue";
    import DropdownMenuItem from "@nova/components/Dropdowns/DropdownMenuItem.vue";
    import VButton from "@nova/components/Buttons/VButton.vue";

    export default {
        emits: ["actionExecuted", "show-preview"],

        components: {
            ActionDropdown,
            DropdownMenuItem,
            VButton,
        },

        props: {
            resource: { type: Object },
            actions: { type: Array },
            viaManyToMany: { type: Boolean },

            ...mapProps([
                "resourceName",
                "viaResource",
                "viaResourceId",
                "viaRelationship",
            ]),
        },

        methods: mapActions(["startImpersonating"]),

        computed: mapGetters(["currentUser"]),
    };
</script>
