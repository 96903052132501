<template>
    <button
        type="button"
        title="Copy to Clipboard"
        @click="handleClick"
        class="inline-flex items-center gap-x-1"
    >
        <slot />

        <icon
            v-if="!copied"
            name="mdi-content-copy"
            class="!w-4 !h-4 text-blue-500 hover:text-blue-700 cursor-pointer"
        />
        <icon
            v-else
            name="mdi-check-circle"
            class="!w-4 !h-4 text-white bg-green-500 rounded-lg cursor-pointer"
        />
    </button>
</template>

<script>
    import debounce from "lodash/debounce";
    import Icon from "@nova/components/Icon.vue";

    export default {
        components: {
            Icon,
        },
        debouncedHandleClick: null,
        data: () => ({
            copied: false,
        }),
        methods: {
            handleClick() {
                this.debouncedHandleClick();
            },
        },
        created() {
            this.debouncedHandleClick = debounce(
                () => {
                    this.copied = true;
                    setTimeout(() => (this.copied = false), 2000);
                },
                2000,
                {
                    leading: true,
                    trailing: false,
                },
            );
        },
    };
</script>
