<script>
    import ResourcePage from "./ResourcePage.vue";
    import debounce from "lodash/debounce";
    import filter from "lodash/filter";
    import find from "lodash/find";
    import identity from "lodash/identity";
    import includes from "lodash/includes";
    import map from "lodash/map";
    import pickBy from "lodash/pickBy";
    import { computed, nextTick } from "vue";
    import { mapProps } from "@nova/utils";

    const filterDockOptions = [
        {
            value: "undock",
            label: "Undock into Dropdown",
            icon: "mdi-dock-window",
        },
        {
            value: "top",
            label: "Dock to Top",
            icon: "mdi-dock-top",
        },
        {
            value: "left",
            label: "Dock to Left",
            icon: "mdi-dock-left",
        },
    ];

    export default {
        extends: ResourcePage,

        props: {
            ...mapProps(["disablePagination"]),

            field: Object,
            initialPerPage: Number,
        },

        provide() {
            // prettier-ignore
            return {
                actionQueryString: computed(() => this.actionQueryString),
                allMatchingResourceCount: computed(() => this.allMatchingResourceCount),
                authorizedToDeleteAnyResources: computed(() => this.authorizedToDeleteAnyResources),
                authorizedToDeleteSelectedResources: computed(() => this.authorizedToDeleteSelectedResources),
                authorizedToForceDeleteAnyResources: computed(() => this.authorizedToForceDeleteAnyResources),
                authorizedToForceDeleteSelectedResources: computed(() => this.authorizedToForceDeleteSelectedResources),
                authorizedToRestoreAnyResources: computed(() => this.authorizedToRestoreAnyResources),
                authorizedToRestoreSelectedResources: computed(() => this.authorizedToRestoreSelectedResources),
                authorizedToUpdateAnyResources: computed(() => this.authorizedToUpdateAnyResources),
                authorizedToViewAnyResources: computed(() => this.authorizedToViewAnyResources),
                availableActions: computed(() => this.availableActions),
                currentlyPolling: computed(() => this.currentlyPolling),
                filterDockOptions: computed(() => this.filterDockOptions),
                filterDockTarget: computed(() => this.filterDockTarget),
                filterDockPinnedTarget: computed(() => this.filterDockPinnedTarget),
                hasFilters: computed(() => this.hasFilters),
                haveStandaloneActions: computed(() => this.haveStandaloneActions),
                orderByDirectionParameter: computed(() => this.orderByDirectionParameter),
                orderByParameter: computed(() => this.orderByParameter),
                perPage: computed(() => this.perPage),
                perPageOptions: computed(() => this.perPageOptions),
                pivotActions: computed(() => this.pivotActions),
                pivotName: computed(() => this.pivotName),
                relationshipType: computed(() => this.relationshipType),
                resourceHasId: computed(() => this.resourceHasId),
                resourceInformation: computed(() => this.resourceInformation),
                resourceName: computed(() => this.resourceName),
                resources: computed(() => this.resources),
                search: computed(() => this.search),
                searchable: computed(() => this.searchable),
                selectAllAndSelectAllMatchingChecked: computed(() => this.selectAllAndSelectAllMatchingChecked),
                selectAllChecked: computed(() => this.selectAllChecked),
                selectAllIndeterminate: computed(() => this.selectAllIndeterminate),
                selectAllMatchingChecked: computed(() => this.selectAllMatchingChecked),
                selectAllOrSelectAllMatchingChecked: computed(() => this.selectAllOrSelectAllMatchingChecked),
                selectedFilterDockOption: computed(() => this.selectedFilterDockOption),
                selectedResources: computed(() => this.selectedResources),
                selectedResourcesCount: computed(() => this.selectedResources.length),
                selectedResourcesForActionSelector: computed(() => this.selectedResourcesForActionSelector),
                shouldShowActionSelector: computed(() => this.shouldShowActionSelector),
                shouldShowCheckboxes: computed(() => this.shouldShowCheckboxes),
                shouldShowDeleteMenu: computed(() => this.shouldShowDeleteMenu),
                shouldShowPollingToggle: computed(() => this.shouldShowPollingToggle),
                softDeletes: computed(() => this.softDeletes),
                trashed: computed(() => this.trashed),
                trashedParameter: computed(() => this.trashedParameter),
                viaManyToMany: computed(() => this.viaManyToMany),
                viaRelationship: computed(() => this.viaRelationship),
                viaResource: computed(() => this.viaResource),
                viaResourceId: computed(() => this.viaResourceId),
            };
        },

        data: () => ({
            actions: [],
            allMatchingResourceCount: null,
            authorizedToRelate: false,
            actionCanceller: null,
            canceller: null,
            currentPageLoadMore: null,
            deleteModalOpen: false,
            currentlyPolling: false,
            filterDockOptions,
            filterHasLoaded: false,
            filterIsActive: false,
            initialLoading: true,
            loading: true,
            loadingMore: false,
            orderBy: "",
            orderByDirection: "",
            perPage: 25,
            pivotActions: null,
            pollingListener: null,
            resourceHasActions: false,
            resourceHasId: true,
            resourceResponse: null,
            resourceResponseError: null,
            resources: [],
            search: "",
            selectAllMatchingResources: false,
            selectedFilterDockOption: filterDockOptions[0],
            selectedResources: [],
            softDeletes: false,
            sortable: true,
            trashed: "",
        }),

        async created() {
            if (Nova.missingResource(this.resourceName)) {
                return Nova.visit("/404");
            }

            const debouncer = debounce(
                (callback) => callback(),
                this.resourceInformation.debounce,
            );

            this.initializeSearchFromQueryString();
            this.initializePerPageFromQueryString();
            this.initializeTrashedFromQueryString();
            this.initializeOrderingFromQueryString();

            await Promise.all([
                this.initializeFilters(this.lensKey || null).then(() => {
                    this.getResources();
                }),
                !this.isLensView ? this.getAuthorizationToRelate() : null,
                this.getActions(),
                !this.isLensView ? this.getLenses() : null,
            ]);

            this.initialLoading = false;

            await nextTick();

            this.selectedFilterDockOption = this.defaultDockOption;

            this.$watch(
                () => {
                    return (
                        this.lensKey +
                        this.resourceName +
                        this.encodedFilters +
                        this.currentSearch +
                        this.currentPage +
                        this.currentPerPage +
                        this.currentOrderBy +
                        this.currentOrderByDirection +
                        this.currentTrashed
                    );
                },
                () => {
                    if (this.canceller !== null) {
                        this.canceller();
                    }

                    if (this.currentPage === 1) {
                        this.currentPageLoadMore = null;
                    }

                    this.getResources();
                },
            );

            this.$watch("search", (newValue) => {
                this.search = newValue;
                debouncer(() => this.performSearch());
            });

            Nova.$on("refresh-resources", this.getResources);
        },

        beforeUnmount() {
            if (this.canceller !== null) {
                this.canceller();
            }

            this.stopPolling();

            Nova.$off("refresh-resources", this.getResources);
        },

        methods: {
            /*
            |--------------------------------------------------------------------------
            | Index Concerns
            |--------------------------------------------------------------------------
            */

            handleResourcesLoaded() {
                this.loading = false;

                if (!this.isLensView && this.resourceResponse.total !== null) {
                    this.allMatchingResourceCount = this.resourceResponse.total;
                } else if (!this.withoutPaginationCount) {
                    this.getAllMatchingResourceCount();
                }

                Nova.$emit(
                    "resources-loaded",
                    this.isLensView
                        ? {
                              resourceName: this.resourceName,
                              lens: this.lensKey,
                              mode: "lens",
                          }
                        : {
                              resourceName: this.resourceName,
                              mode: this.isRelation ? "related" : "index",
                          },
                );

                this.initializePolling();
            },

            selectAllResources() {
                this.selectedResources = this.resources.slice(0);
            },

            toggleSelectAll(e) {
                if (e) {
                    e.preventDefault();
                }

                if (this.selectAllChecked) {
                    this.clearResourceSelections();
                } else {
                    this.selectAllResources();
                }

                // this.getActions();
            },

            toggleSelectAllMatching(e) {
                if (e) {
                    e.preventDefault();
                }

                if (!this.selectAllMatchingResources) {
                    this.selectAllResources();
                    this.selectAllMatchingResources = true;
                } else {
                    this.selectAllMatchingResources = false;
                }

                // this.getActions();
            },

            updateSelectionStatus(resource) {
                if (!includes(this.selectedResources, resource)) {
                    this.selectedResources.push(resource);
                } else {
                    const index = this.selectedResources.indexOf(resource);
                    if (index > -1) {
                        this.selectedResources.splice(index, 1);
                    }
                }

                this.selectAllMatchingResources = false;

                // this.getActions();
            },

            clearResourceSelections() {
                this.selectAllMatchingResources = false;
                this.selectedResources = [];
            },

            orderByField(field) {
                let direction =
                    this.currentOrderByDirection == "asc" ? "desc" : "asc";

                if (this.currentOrderBy != field.sortableUriKey) {
                    direction = "asc";
                }

                this.updateQueryString({
                    [this.orderByParameter]: field.sortableUriKey,
                    [this.orderByDirectionParameter]: direction,
                });
            },

            resetOrderBy(field) {
                this.updateQueryString({
                    [this.orderByParameter]: field.sortableUriKey,
                    [this.orderByDirectionParameter]: null,
                });
            },

            initializeSearchFromQueryString() {
                this.search = this.currentSearch;
            },

            initializeOrderingFromQueryString() {
                this.orderBy = this.currentOrderBy;
                this.orderByDirection = this.currentOrderByDirection;
            },

            initializeTrashedFromQueryString() {
                this.trashed = this.currentTrashed;
            },

            trashedChanged(trashedStatus) {
                this.trashed = trashedStatus;
                this.updateQueryString({
                    [this.trashedParameter]: this.trashed,
                });
            },

            updatePerPageChanged(perPage) {
                this.perPage = perPage;
                this.perPageChanged();
            },

            selectPage(page) {
                this.updateQueryString({ [this.pageParameter]: page });
            },

            initializePerPageFromQueryString() {
                this.perPage =
                    this.queryStringParams[this.perPageParameter] ||
                    this.initialPerPage ||
                    this.resourceInformation?.perPageOptions[0] ||
                    null;
            },

            closeDeleteModal() {
                this.deleteModalOpen = false;
            },

            performSearch() {
                this.updateQueryString({
                    [this.pageParameter]: 1,
                    [this.searchParameter]: this.search,
                });
            },

            handleActionExecuted(action) {
                if (action.withoutRefreshing) {
                    return;
                }

                this.fetchPolicies();
                this.getResources();
            },

            /*
            |--------------------------------------------------------------------------
            | Deletable
            |--------------------------------------------------------------------------
            */

            openDeleteModal() {
                this.deleteModalOpen = true;
            },

            deleteResources(resources, callback = null) {
                if (this.viaManyToMany) {
                    return this.detachResources(resources);
                }

                return Nova.request({
                    url: "/nova-api/" + this.resourceName,
                    method: "delete",
                    params: {
                        ...this.deletableQueryString,
                        ...{ resources: mapResources(resources) },
                    },
                })
                    .then(
                        callback
                            ? callback
                            : () => {
                                  this.getResources();
                              },
                    )
                    .then(() => {
                        Nova.$emit("resources-deleted");
                    })
                    .finally(() => {
                        this.deleteModalOpen = false;
                    });
            },

            deleteSelectedResources() {
                this.deleteResources(this.selectedResources);
            },

            deleteAllMatchingResources() {
                if (this.viaManyToMany) {
                    return this.detachAllMatchingResources();
                }

                return Nova.request({
                    url: this.deleteAllMatchingResourcesEndpoint,
                    method: "delete",
                    params: {
                        ...this.deletableQueryString,
                        ...{ resources: "all" },
                    },
                })
                    .then(() => {
                        this.getResources();
                    })
                    .then(() => {
                        Nova.$emit("resources-deleted");
                    })
                    .finally(() => {
                        this.deleteModalOpen = false;
                    });
            },

            detachResources(resources) {
                return Nova.request({
                    url: "/nova-api/" + this.resourceName + "/detach",
                    method: "delete",
                    params: {
                        ...this.deletableQueryString,
                        ...{ resources: mapResources(resources) },
                        ...{ pivots: mapPivots(resources) },
                    },
                })
                    .then(() => {
                        this.getResources();
                    })
                    .then(() => {
                        Nova.$emit("resources-detached");
                    })
                    .finally(() => {
                        this.deleteModalOpen = false;
                    });
            },

            detachAllMatchingResources() {
                return Nova.request({
                    url: "/nova-api/" + this.resourceName + "/detach",
                    method: "delete",
                    params: {
                        ...this.deletableQueryString,
                        ...{ resources: "all" },
                    },
                })
                    .then(() => {
                        this.getResources();
                    })
                    .then(() => {
                        Nova.$emit("resources-detached");
                    })
                    .finally(() => {
                        this.deleteModalOpen = false;
                    });
            },

            forceDeleteResources(resources, callback = null) {
                return Nova.request({
                    url: "/nova-api/" + this.resourceName + "/force",
                    method: "delete",
                    params: {
                        ...this.deletableQueryString,
                        ...{ resources: mapResources(resources) },
                    },
                })
                    .then(
                        callback
                            ? callback
                            : () => {
                                  this.getResources();
                              },
                    )
                    .then(() => {
                        Nova.$emit("resources-deleted");
                    })
                    .finally(() => {
                        this.deleteModalOpen = false;
                    });
            },

            forceDeleteSelectedResources() {
                this.forceDeleteResources(this.selectedResources);
            },

            forceDeleteAllMatchingResources() {
                return Nova.request({
                    url: this.forceDeleteSelectedResourcesEndpoint,
                    method: "delete",
                    params: {
                        ...this.deletableQueryString,
                        ...{ resources: "all" },
                    },
                })
                    .then(() => {
                        this.getResources();
                    })
                    .then(() => {
                        Nova.$emit("resources-deleted");
                    })
                    .finally(() => {
                        this.deleteModalOpen = false;
                    });
            },

            restoreResources(resources, callback = null) {
                return Nova.request({
                    url: "/nova-api/" + this.resourceName + "/restore",
                    method: "put",
                    params: {
                        ...this.deletableQueryString,
                        ...{ resources: mapResources(resources) },
                    },
                })
                    .then(
                        callback
                            ? callback
                            : () => {
                                  this.getResources();
                              },
                    )
                    .then(() => {
                        Nova.$emit("resources-restored");
                    })
                    .finally(() => {
                        this.restoreModalOpen = false;
                    });
            },

            restoreSelectedResources() {
                this.restoreResources(this.selectedResources);
            },

            restoreAllMatchingResources() {
                return Nova.request({
                    url: this.restoreAllMatchingResourcesEndpoint,
                    method: "put",
                    params: {
                        ...this.deletableQueryString,
                        ...{ resources: "all" },
                    },
                })
                    .then(() => {
                        this.getResources();
                    })
                    .then(() => {
                        Nova.$emit("resources-restored");
                    })
                    .finally(() => {
                        this.restoreModalOpen = false;
                    });
            },

            /*
            |--------------------------------------------------------------------------
            | Filterable
            |--------------------------------------------------------------------------
            */

            async clearSelectedFilters(lens) {
                if (lens) {
                    await this.$store.dispatch(
                        `${this.resourceName}/resetFilterState`,
                        {
                            resourceName: this.resourceName,
                            lens,
                        },
                    );
                } else {
                    await this.$store.dispatch(
                        `${this.resourceName}/resetFilterState`,
                        {
                            resourceName: this.resourceName,
                        },
                    );
                }

                this.updateQueryString({
                    [this.pageParameter]: 1,
                    [this.filterParameter]: "",
                });

                Nova.$emit("filter-reset");
            },

            filterChanged() {
                let filtersAreApplied =
                    this.$store.getters[
                        `${this.resourceName}/filtersAreApplied`
                    ];

                if (filtersAreApplied || this.filterIsActive) {
                    this.filterIsActive = true;
                    this.updateQueryString({
                        [this.pageParameter]: 1,
                        [this.filterParameter]: this.encodedFilters,
                    });
                }
            },

            async initializeFilters(lens) {
                if (this.filterHasLoaded === true) {
                    return;
                }

                // Clear out the filters from the store first
                this.$store.commit(`${this.resourceName}/clearFilters`);

                await this.$store.dispatch(
                    `${this.resourceName}/fetchFilters`,
                    pickBy(
                        {
                            resourceName: this.resourceName,
                            viaResource: this.viaResource,
                            viaResourceId: this.viaResourceId,
                            viaRelationship: this.viaRelationship,
                            relationshipType: this.relationshipType,
                            lens,
                        },
                        identity,
                    ),
                );

                await this.initializeState(lens);

                this.filterHasLoaded = true;
            },

            async initializeState(lens) {
                this.initialEncodedFilters
                    ? await this.$store.dispatch(
                          `${this.resourceName}/initializeCurrentFilterValuesFromQueryString`,
                          this.initialEncodedFilters,
                      )
                    : await this.$store.dispatch(
                          `${this.resourceName}/resetFilterState`,
                          {
                              resourceName: this.resourceName,
                              lens,
                          },
                      );
            },

            filterDockChanged(option) {
                this.selectedFilterDockOption = option;
            },

            getFilterDockTarget(option) {
                return `[data-resource=${this.resourceName}] [data-dock=${option}]`;
            },

            /*
            |--------------------------------------------------------------------------
            | Pagination
            |--------------------------------------------------------------------------
            */

            selectPreviousPage() {
                this.updateQueryString({
                    [this.pageParameter]: this.currentPage - 1,
                });
            },

            selectNextPage() {
                this.updateQueryString({
                    [this.pageParameter]: this.currentPage + 1,
                });
            },

            initializePerPageFromQueryString() {
                this.perPage = this.currentPerPage;
            },

            perPageChanged() {
                this.updateQueryString({
                    [this.perPageParameter]: this.perPage,
                });
            },

            /*
            |--------------------------------------------------------------------------
            | Polling
            |--------------------------------------------------------------------------
            */

            initializePolling() {
                this.currentlyPolling =
                    this.currentlyPolling || this.resourceResponse.polling;

                if (this.currentlyPolling && this.pollingListener === null) {
                    return this.startPolling();
                }
            },

            togglePolling() {
                if (this.currentlyPolling) {
                    this.stopPolling();
                } else {
                    this.startPolling();
                }
            },

            stopPolling() {
                if (this.pollingListener) {
                    clearInterval(this.pollingListener);
                    this.pollingListener = null;
                }

                this.currentlyPolling = false;
            },

            startPolling() {
                this.pollingListener = setInterval(() => {
                    const selectedResources = this.selectedResources ?? [];

                    if (
                        document.hasFocus() &&
                        document.querySelectorAll("[data-modal-open]").length <
                            1 &&
                        selectedResources.length < 1
                    ) {
                        this.pollingRatio = 0;
                        this.getResources();
                    }
                }, this.pollingInterval);

                this.currentlyPolling = true;
            },

            restartPolling() {
                if (this.currentlyPolling === true) {
                    this.stopPolling();
                    this.startPolling();
                }
            },
        },

        computed: {
            /*
            |--------------------------------------------------------------------------
            | Index Concerns
            |--------------------------------------------------------------------------
            */
            hasFilters() {
                return this.$store.getters[`${this.resourceName}/hasFilters`];
            },

            pageParameter() {
                return this.viaRelationship
                    ? this.viaRelationship + "_page"
                    : this.resourceName + "_page";
            },

            selectAllChecked() {
                return this.selectedResources.length == this.resources.length;
            },

            selectAllIndeterminate() {
                return (
                    Boolean(
                        this.selectAllChecked || this.selectAllMatchingChecked,
                    ) && Boolean(!this.selectAllAndSelectAllMatchingChecked)
                );
            },

            selectAllAndSelectAllMatchingChecked() {
                return this.selectAllChecked && this.selectAllMatchingChecked;
            },

            selectAllOrSelectAllMatchingChecked() {
                return this.selectAllChecked || this.selectAllMatchingChecked;
            },

            selectAllMatchingChecked() {
                return this.selectAllMatchingResources;
            },

            selectedResourceIds() {
                return map(
                    this.selectedResources,
                    (resource) => resource.id.value,
                );
            },

            selectedPivotIds() {
                return map(
                    this.selectedResources,
                    (resource) => resource.id.pivotValue ?? null,
                );
            },

            currentSearch() {
                return this.queryStringParams[this.searchParameter] || "";
            },

            currentOrderBy() {
                return this.queryStringParams[this.orderByParameter] || "";
            },

            currentOrderByDirection() {
                return (
                    this.queryStringParams[this.orderByDirectionParameter] ||
                    null
                );
            },

            currentTrashed() {
                return this.queryStringParams[this.trashedParameter] || "";
            },

            viaManyToMany() {
                return (
                    this.relationshipType == "belongsToMany" ||
                    this.relationshipType == "morphToMany"
                );
            },

            isRelation() {
                return Boolean(this.viaResourceId && this.viaRelationship);
            },

            singularName() {
                if (this.isRelation && this.field) {
                    return this.field.singularLabel;
                }

                if (this.resourceInformation) {
                    return this.resourceInformation.singularLabel;
                }
            },

            hasResources() {
                return Boolean(this.resources.length > 0);
            },

            hasLenses() {
                return Boolean(this.lenses.length > 0);
            },

            shouldShowCards() {
                // Don't show cards if this resource is beings shown via a relations
                return Boolean(this.cards.length > 0 && !this.isRelation);
            },

            shouldShowCheckboxes() {
                return (
                    Boolean(this.hasResources) &&
                    Boolean(this.resourceHasId) &&
                    Boolean(
                        this.resourceHasActions ||
                            this.authorizedToDeleteAnyResources ||
                            this.canShowDeleteMenu,
                    )
                );
            },

            shouldShowDeleteMenu() {
                return (
                    Boolean(this.selectedResources.length > 0) &&
                    this.canShowDeleteMenu
                );
            },

            canShowDeleteMenu() {
                return Boolean(
                    this.authorizedToDeleteSelectedResources ||
                        this.authorizedToForceDeleteSelectedResources ||
                        this.authorizedToRestoreSelectedResources ||
                        this.selectAllMatchingChecked,
                );
            },

            authorizedToDeleteSelectedResources() {
                return Boolean(
                    find(
                        this.selectedResources,
                        (resource) => resource.authorizedToDelete,
                    ),
                );
            },

            authorizedToForceDeleteSelectedResources() {
                return Boolean(
                    find(
                        this.selectedResources,
                        (resource) => resource.authorizedToForceDelete,
                    ),
                );
            },

            authorizedToViewAnyResources() {
                return (
                    this.resources.length > 0 &&
                    Boolean(this.resourceHasId) &&
                    Boolean(
                        find(
                            this.resources,
                            (resource) => resource.authorizedToView,
                        ),
                    )
                );
            },

            authorizedToUpdateAnyResources() {
                return (
                    this.resources.length > 0 &&
                    Boolean(this.resourceHasId) &&
                    Boolean(
                        find(
                            this.resources,
                            (resource) => resource.authorizedToUpdate,
                        ),
                    )
                );
            },

            authorizedToDeleteAnyResources() {
                return (
                    this.resources.length > 0 &&
                    Boolean(this.resourceHasId) &&
                    Boolean(
                        find(
                            this.resources,
                            (resource) => resource.authorizedToDelete,
                        ),
                    )
                );
            },

            authorizedToForceDeleteAnyResources() {
                return (
                    this.resources.length > 0 &&
                    Boolean(this.resourceHasId) &&
                    Boolean(
                        find(
                            this.resources,
                            (resource) => resource.authorizedToForceDelete,
                        ),
                    )
                );
            },

            authorizedToRestoreSelectedResources() {
                return (
                    Boolean(this.resourceHasId) &&
                    Boolean(
                        find(
                            this.selectedResources,
                            (resource) => resource.authorizedToRestore,
                        ),
                    )
                );
            },

            authorizedToRestoreAnyResources() {
                return (
                    this.resources.length > 0 &&
                    Boolean(this.resourceHasId) &&
                    Boolean(
                        find(
                            this.resources,
                            (resource) => resource.authorizedToRestore,
                        ),
                    )
                );
            },

            initialEncodedFilters() {
                return this.queryStringParams[this.filterParameter] || "";
            },

            paginationComponent() {
                return `pagination-${Nova.config("pagination") || "links"}`;
            },

            hasNextPage() {
                return Boolean(
                    this.resourceResponse &&
                        this.resourceResponse.next_page_url,
                );
            },

            hasPreviousPage() {
                return Boolean(
                    this.resourceResponse &&
                        this.resourceResponse.prev_page_url,
                );
            },

            totalPages() {
                return Math.ceil(
                    this.allMatchingResourceCount / this.currentPerPage,
                );
            },

            resourceCountLabel() {
                const first = this.perPage * (this.currentPage - 1);

                const formattedFirst = Nova.formatNumber(first + 1);
                const formattedLast = Nova.formatNumber(
                    first + this.resources.length,
                );
                const formattedCount = Nova.formatNumber(
                    this.allMatchingResourceCount,
                );

                if (!this.resources.length) {
                    return false;
                }

                if (this.allMatchingResourceCount) {
                    return `${formattedFirst}-${formattedLast} ${this.__("of")} ${formattedCount}`;
                }

                return `${formattedFirst}-${formattedLast}`;
            },

            currentPerPage() {
                return this.perPage;
            },

            perPageOptions() {
                if (this.resourceResponse) {
                    return this.resourceResponse.per_page_options;
                }
            },

            createButtonLabel() {
                if (this.resourceInformation)
                    return this.resourceInformation.createButtonLabel;

                return this.__("Create");
            },

            resourceRequestQueryString() {
                return pickBy(
                    {
                        search: this.currentSearch,
                        filters: this.encodedFilters,
                        orderBy: this.currentOrderBy,
                        orderByDirection: this.currentOrderByDirection,
                        perPage: this.currentPerPage,
                        trashed: this.currentTrashed,
                        page: this.currentPage,
                        viaResource: this.viaResource,
                        viaResourceId: this.viaResourceId,
                        viaRelationship: this.viaRelationship,
                        viaResourceRelationship: this.viaResourceRelationship,
                        relationshipType: this.relationshipType,
                        morphToType: this.field?.morphToType,
                        morphToId: this.field?.morphToId,
                    },
                    (v) => v,
                );
            },

            shouldShowActionSelector() {
                return this.selectedResources.length > 0;
            },

            isLensView() {
                return (
                    this.lensKey !== "" &&
                    this.lensKey != undefined &&
                    this.lensKey != null
                );
            },

            shouldShowPagination() {
                return (
                    this.disablePagination !== true &&
                    this.resourceResponse &&
                    (this.hasResources || this.hasPreviousPage)
                );
            },

            currentResourceCount() {
                return this.resources.length;
            },

            searchParameter() {
                return this.viaRelationship
                    ? this.viaRelationship + "_search"
                    : this.resourceName + "_search";
            },

            orderByParameter() {
                return this.viaRelationship
                    ? this.viaRelationship + "_order"
                    : this.resourceName + "_order";
            },

            orderByDirectionParameter() {
                return this.viaRelationship
                    ? this.viaRelationship + "_direction"
                    : this.resourceName + "_direction";
            },

            trashedParameter() {
                return this.viaRelationship
                    ? this.viaRelationship + "_trashed"
                    : this.resourceName + "_trashed";
            },

            perPageParameter() {
                return this.viaRelationship
                    ? this.viaRelationship + "_per_page"
                    : this.resourceName + "_per_page";
            },

            haveStandaloneActions() {
                return (
                    filter(this.allActions, (a) => a.standalone === true)
                        .length > 0
                );
            },

            availableActions() {
                return this.actions;
            },

            hasPivotActions() {
                return (
                    this.pivotActions && this.pivotActions.actions.length > 0
                );
            },

            pivotName() {
                return this.pivotActions ? this.pivotActions.name : "";
            },

            actionsAreAvailable() {
                return this.allActions.length > 0;
            },

            allActions() {
                return this.hasPivotActions
                    ? this.actions.concat(this.pivotActions.actions)
                    : this.actions;
            },

            availableStandaloneActions() {
                return this.allActions.filter((a) => a.standalone === true);
            },

            availableStandaloneRunActions() {
                return this.availableStandaloneActions.filter((a) => !a.link);
            },

            availableStandaloneLinkActions() {
                return this.availableStandaloneActions.filter((a) => a.link);
            },

            selectedResourcesForActionSelector() {
                // prettier-ignore
                return (this.selectAllMatchingChecked || !this.shouldShowCheckboxes)
                    ? "all"
                    : this.selectedResources;
            },

            actionQueryString() {
                return {
                    currentSearch: this.currentSearch,
                    encodedFilters: this.encodedFilters,
                    currentTrashed: this.currentTrashed,
                    viaResource: this.viaResource,
                    viaResourceId: this.viaResourceId,
                    viaRelationship: this.viaRelationship,
                };
            },

            /*
            |--------------------------------------------------------------------------
            | Filterable
            |--------------------------------------------------------------------------
            */

            filterParameter() {
                return this.resourceName + "_filter";
            },

            encodedFilters() {
                return this.$store.getters[
                    `${this.resourceName}/currentEncodedFilters`
                ];
            },

            filterDockTarget() {
                return this.getFilterDockTarget(
                    this.selectedFilterDockOption.value,
                );
            },

            filterDockTop() {
                return this.selectedFilterDockOption.value === "top";
            },

            filterDockPinnedTarget() {
                return this.filterDockLeft
                    ? this.getFilterDockTarget("left")
                    : this.getFilterDockTarget("top");
            },

            filterDockLeft() {
                return this.selectedFilterDockOption.value === "left";
            },

            defaultDockOption() {
                // prettier-ignore
                const value = this.viaResource
                    ? "undock"
                    : (this.defaultDockOptionStyle || "undock");

                return find(filterDockOptions, { value });
            },

            defaultDockOptionStyle() {
                return this.resourceInformation.defaultFilterDockStyle;
            },

            searchable() {
                return (
                    this.resourceInformation &&
                    this.resourceInformation.searchable
                );
            },

            /*
            |--------------------------------------------------------------------------
            | Polling
            |--------------------------------------------------------------------------
            */

            initiallyPolling() {
                return this.resourceResponse.polling;
            },

            pollingInterval() {
                return this.resourceResponse.pollingInterval;
            },

            pollingRatioInterval() {
                return this.resourceResponse.pollingRatioInterval || 100;
            },

            shouldShowPollingToggle() {
                if (!this.resourceResponse) {
                    return false;
                }

                return this.resourceResponse.showPollingToggle || false;
            },

            /*
            |--------------------------------------------------------------------------
            | Pagination
            |--------------------------------------------------------------------------
            */

            currentPage() {
                return parseInt(
                    this.queryStringParams[this.pageParameter] || 1,
                );
            },

            currentPerPage() {
                return this.queryStringParams[this.perPageParameter] || 25;
            },

            withoutPaginationCount() {
                return this.resourceInformation.withoutPaginationCount;
            },

            /*
            |--------------------------------------------------------------------------
            | Deletable
            |--------------------------------------------------------------------------
            */

            deleteAllMatchingResourcesEndpoint() {
                if (this.lens) {
                    return (
                        "/nova-api/" +
                        this.resourceName +
                        "/lens/" +
                        this.lensKey
                    );
                }

                return "/nova-api/" + this.resourceName;
            },

            forceDeleteSelectedResourcesEndpoint() {
                if (this.lens) {
                    return (
                        "/nova-api/" +
                        this.resourceName +
                        "/lens/" +
                        this.lensKey +
                        "/force"
                    );
                }

                return "/nova-api/" + this.resourceName + "/force";
            },

            restoreAllMatchingResourcesEndpoint() {
                if (this.lens) {
                    return (
                        "/nova-api/" +
                        this.resourceName +
                        "/lens/" +
                        this.lensKey +
                        "/restore"
                    );
                }

                return "/nova-api/" + this.resourceName + "/restore";
            },

            deletableQueryString() {
                return {
                    search: this.currentSearch,
                    filters: this.encodedFilters,
                    trashed: this.currentTrashed,
                    viaResource: this.viaResource,
                    viaResourceId: this.viaResourceId,
                    viaRelationship: this.viaRelationship,
                };
            },
        },
    };

    function mapResources(resources) {
        return map(resources, (resource) => resource.id.value);
    }

    function mapPivots(resources) {
        return filter(map(resources, (resource) => resource.id.pivotValue));
    }
</script>
