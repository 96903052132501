import { defineAsyncComponent } from "vue";

function defineModuleComponent(module, options) {
    const component = module.default;

    const computed = component.computed;

    component.computed = {
        ...computed,
        $pluginOptions: () => options || {},
    };

    return component;
}

/**
 * @see https://zerotomastery.io/blog/how-to-auto-register-components-for-vue-with-vite/
 */
export default function (app, files, options = {}, prefix = "") {
    Object.entries(files).forEach(([path, resolver]) => {
        const componentName = path.split("/").pop().split(".")[0];

        const component =
            typeof resolver === "object"
                ? defineModuleComponent(resolver, options)
                : defineAsyncComponent(async () => {
                      return defineModuleComponent(await resolver(), options);
                  });

        app.component(prefix + componentName, component);
    });
}
