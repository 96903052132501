<template>
    <loading-metric
        component="base-multi-value-trend-metric"
        v-bind="$attrs"
        :card="card"
        :params="{
            ...params,
            range: selectedRangeKey,
        }"
    >
        <template #controls="slotProps">
            <select
                v-if="slotProps.card.ranges.length > 0"
                v-model="selectedRangeKey"
            >
                <option
                    v-for="option in slotProps.card.ranges"
                    :key="option.value"
                    :value="option.value"
                    v-text="option.label"
                />
            </select>
        </template>
    </loading-metric>
</template>

<script>
    import LoadingMetric from "@nova/components/Metrics/LoadingMetric.vue";

    export default {
        components: {
            LoadingMetric,
        },
        props: {
            card: {
                type: Object,
                default: {},
            },
            params: {
                type: Object,
                required: false,
            },
        },
        data() {
            return {
                selectedRangeKey:
                    this.defaultRange || this.card.ranges[0]?.value,
            };
        },
    };
</script>
