<script>
    import { formatNumber } from "@nova/utils";

    export default {
        props: {
            title: String,

            /**
             * Data is shaped by the child metric.
             *
             * @property {Object}
             */
            data: {
                type: Object,
                required: true,
            },

            /**
             * @link https://github.com/BenjaminVanRyseghem/numbro/blob/master/src/validating.js
             *
             * @property {Object}           format
             * @property {string="number"}  format.output ("currency"|"percent"|"byte"|"time"|"ordinal"|"number")
             * @property {string="decimal"} format.base ("decimal"|"binary"|"general")
             * @property {string=}          format.prefix (e.g. "$")
             * @property {string=}          format.postfix (e.g. "%")
             * @property {string=}          format.forceAverage ("thousand"|"million"|"billion"|"trillion")
             * @property {boolean=false}    format.average
             * @property {boolean=false}    format.lowPrecision
             * @property {string="prefix"}  format.currencyPosition ("prefix"|"infix"|"postfix")
             * @property {string=}          format.currencySymbol (e.g. "$")
             * @property {Number=}          format.totalLength
             * @property {Number=}          format.mantissa
             * @property {boolean=false}    format.optionalMantissa
             * @property {boolean=false}    format.trimMantissa
             * @property {function=}        format.roundingFunction
             * @property {boolean=true}     format.thousandSeparated
             * @property {boolean=false}    format.spaceSeparated
             * @property {string=}          format.negative ("sign"|"parenthesis")
             * @property {boolean=false}    format.forceSign
             * @property {string=}          format.unit ("seconds"|"minutes"|"hours"|"days"|"weeks"|"years")
             */
            format: {
                type: Object,
                default: {
                    average: true,
                    mantissa: 2,
                },
            },
            prefix: String,
            suffix: String,
        },
        methods: {
            formatNumber,
            getDeltaColor(sign) {
                switch (sign) {
                    case 1:
                        return this.colors.increase;
                    case 0:
                        return this.colors.muted;
                    case -1:
                        return this.colors.decrease;
                }
            },
            getDeltaIcon(sign) {
                switch (sign) {
                    case 1:
                        return "heroicon-c-arrow-up";
                    case -1:
                        return "heroicon-c-arrow-down";
                    default:
                        return "heroicon-m-bars-2";
                }
            },
            getDeltaPercentage(ratio) {
                const percent = Math.abs(ratio) * 100;

                return formatNumber(percent, {
                    mantissa: 1,
                });
            },
            getIncreaseOrDecrease(ratio) {
                return Math.sign(ratio);
            },
            getChangeLabel(ratio) {
                if (ratio === 0) {
                    return "No Change";
                }

                if (ratio === null) {
                    return "No Prior Data";
                }

                const percent = formatNumber(Math.abs(ratio * 100), {
                    mantissa: 1,
                });

                const label = ratio > 0 ? "Increase" : "Decrease";

                return `${percent}% ${label}`;
            },
            getChangeRatio(current, previous) {
                if (previous === 0 || previous === null) {
                    return null;
                }

                return (current - previous) / Math.abs(previous);
            },
        },
        computed: {
            colors() {
                const theme = this.$pluginOptions.theme;

                return {
                    primary: theme.colors.blue["500"],
                    secondary: theme.colors.orange["500"],
                    grid: theme.colors.gray["300"],
                    text: theme.colors.gray["900"],
                    muted: theme.colors.gray["500"],
                    increase: theme.colors.green["600"],
                    decrease: theme.colors.red["600"],
                };
            },
        },
    };
</script>
