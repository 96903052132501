<template>
    <Head
        v-if="title"
        :title="titleTag"
    />
</template>

<script>
    import { Head } from "@inertiajs/vue3";

    export default {
        props: {
            title: String,
            section: String,
            subsection: String,
        },
        components: {
            Head,
        },
        computed: {
            titleTag() {
                return [this.subsection || this.title, this.section]
                    .filter((v) => v)
                    .join(" | ");
            },
        },
    };
</script>
