<template>
    <div class="overflow-hidden overflow-x-auto relative bg-white">
        <table class="min-w-full mb-0">
            <resource-table-header
                :resource-name="resourceName"
                :fields="fields"
                :should-show-column-borders="shouldShowColumnBorders"
                :should-show-checkboxes="shouldShowCheckboxes"
                :should-show-actions="resourceInformation.shouldShowActions"
                :sortable="sortable"
                @order="requestOrderByChange"
                @reset-order-by="resetOrderBy"
            />
            <tbody
                :class="[
                    'bg-gray-100 [&_tr:nth-child(even)]:bg-gray-200 border-0',
                    'dark:bg-gray-750 [&_tr:nth-child(even)]:dark:bg-gray-800',
                    '[&_th]:bg-blue-900 [&_th]:text-white [&_th]:text-left [&_th]:border-b [&_th]:border-blue-800',
                ]"
            >
                <resource-table-row
                    v-for="(resource, index) in resources"
                    :key="`${resource.id.value}-items-${index}`"
                    @actionExecuted="$emit('actionExecuted', $event)"
                    @promptDelete="openDeleteModal"
                    @promptRestore="openRestoreModal"
                    :actions-are-available="actionsAreAvailable"
                    :actions-endpoint="actionsEndpoint"
                    :checked="selectedResources.indexOf(resource) > -1"
                    :click-action="clickAction"
                    :relationship-type="relationshipType"
                    :resource-name="resourceName"
                    :resource="resource"
                    :selected-resources="selectedResources"
                    :should-show-actions="resourceInformation.shouldShowActions"
                    :should-show-checkboxes="shouldShowCheckboxes"
                    :should-show-column-borders="shouldShowColumnBorders"
                    :testId="`${resourceName}-items-${index}`"
                    :update-selection-status="updateSelectionStatus"
                    :via-many-to-many="viaManyToMany"
                    :via-relationship="viaRelationship"
                    :via-resource-id="viaResourceId"
                    :via-resource="viaResource"
                />
            </tbody>

            <delete-resource-modal
                :mode="viaManyToMany ? 'detach' : 'delete'"
                :show="deleteModalOpen"
                @close="closeDeleteModal"
                @confirm="confirmDelete"
                v-slot="props"
            >
                <div
                    v-html="
                        `Are you sure you want to ${props.mode} ${resourceInformation.singularLabel} <b>${resourceToDelete.title}</b>?`
                    "
                />
            </delete-resource-modal>

            <restore-resource-modal
                :show="restoreModalOpen"
                @close="closeRestoreModal"
                @confirm="confirmRestore"
            >
                <div
                    v-html="
                        `Are you sure you want to restore ${resourceInformation.singularLabel} <b>${resourceToRestore.title}</b>?`
                    "
                />
            </restore-resource-modal>
        </table>
    </div>
</template>

<script>
    import ResourceTableHeader from "@nova/components/ResourceTableHeader.vue";
    import ResourceTableRow from "@nova/components/ResourceTableRow.vue";
    import DeleteResourceModal from "@nova/components/Modals/DeleteResourceModal.vue";
    import RestoreResourceModal from "@nova/components/Modals/RestoreResourceModal.vue";

    export default {
        emits: [
            "actionExecuted",
            "delete",
            "restore",
            "order",
            "reset-order-by",
        ],

        components: {
            ResourceTableHeader,
            ResourceTableRow,
            DeleteResourceModal,
            RestoreResourceModal,
        },

        inject: [
            "relationshipType",
            "resourceHasId",
            "resourceInformation",
            "resourceName",
            "selectedResources",
            "shouldShowCheckboxes",
            "viaRelationship",
            "viaResource",
            "viaResourceId",
        ],

        props: {
            actionsAreAvailable: { type: Boolean, default: false },
            actionsEndpoint: { default: null },
            authorizedToRelate: { type: Boolean, required: true },
            resources: { default: [] },
            selectedResourceIds: {},
            singularName: { type: String, required: true },
            sortable: { type: Boolean, default: false },
            updateSelectionStatus: { type: Function },
        },

        data: () => ({
            selectAllResources: false,
            selectAllMatching: false,
            resourceCount: null,
            deleteModalOpen: false,
            resourceToDelete: null,
            restoreModalOpen: false,
            resourceToRestore: null,
        }),

        methods: {
            deleteResource(resource) {
                this.$emit("delete", [resource]);
            },

            restoreResource(resource) {
                this.$emit("restore", [resource]);
            },

            requestOrderByChange(field) {
                this.$emit("order", field);
            },

            resetOrderBy(field) {
                this.$emit("reset-order-by", field);
            },

            openDeleteModal(resource) {
                this.resourceToDelete = resource;
                this.deleteModalOpen = true;
            },

            closeDeleteModal() {
                this.resourceToDelete = null;
                this.deleteModalOpen = false;
            },

            confirmDelete() {
                this.deleteResource(this.resourceToDelete);
                this.closeDeleteModal();
            },

            openRestoreModal(resource) {
                this.resourceToRestore = resource;
                this.restoreModalOpen = true;
            },

            closeRestoreModal() {
                this.resourceToRestore = null;
                this.restoreModalOpen = false;
            },

            confirmRestore() {
                this.restoreResource(this.resourceToRestore);
                this.closeRestoreModal();
            },
        },

        computed: {
            fields() {
                if (this.resources) {
                    return this.resources[0].fields;
                }
            },

            viaManyToMany() {
                return (
                    this.relationshipType == "belongsToMany" ||
                    this.relationshipType == "morphToMany"
                );
            },

            shouldShowColumnBorders() {
                return this.resourceInformation.showColumnBorders;
            },

            tableStyle() {
                return this.resourceInformation.tableStyle;
            },

            clickAction() {
                return this.resourceInformation.clickAction;
            },
        },
    };
</script>
