<template>
    <div
        class="flex items-center justify-between py-4 px-8 border-b border-gray-100 dark:border-gray-700"
    >
        <heading
            :level="2"
            class="pb-0"
        >
            <slot />
        </heading>

        <v-button
            type="button"
            variant="ghost"
            severity="muted"
            icon="mdi-close"
            size="lg"
            padding="tight"
            @click="$emit('close')"
        />
    </div>
</template>

<script>
    import VButton from "@nova/components/Buttons/VButton.vue";
    import Heading from "@nova/components/Heading.vue";

    export default {
        emits: ["close"],
        components: {
            VButton,
            Heading,
        },
    };
</script>
