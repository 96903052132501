<template>
    <table-item :field="field">
        <template #field-value="{ item }">
            <a
                class="link-default"
                :href="field.value"
                rel="noreferrer noopener"
                target="_blank"
                @click.stop
            >
                {{ item.fieldValue }}
            </a>
        </template>
    </table-item>
</template>

<script>
    export default {
        props: ["field"],
    };
</script>
