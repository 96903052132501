<template>
    <aside class="lg:w-80 md:w-64 w-full">
        <card>
            <slot />
        </card>
    </aside>
</template>

<script>
    import Card from "@nova/components/Card.vue";

    export default {
        components: {
            Card,
        },
    };
</script>
