<template>
    <card
        class="flex flex-col justify-center items-center gap-2 p-6 text-gray-300 dark:text-gray-500"
        :dusk="`${resourceName}-empty-dialog`"
    >
        <icon
            name="mdi-table-plus"
            class="w-20 h-20"
        />

        <h3 class="text-base font-normal italic m-0 p-0 text-gray-500">
            {{
                __("No :resource could be found.", {
                    resource: resourceLabel,
                })
            }}
        </h3>

        <!-- Create / Attach Button -->
        <create-resource-button
            type="outline"
            :authorized-to-create="authorizedToCreate"
            :authorized-to-relate="authorizedToRelate"
        />
    </card>
</template>

<script>
    import Card from "@nova/components/Card.vue";
    import Icon from "@nova/components/Icon.vue";
    import CreateResourceButton from "@nova/components/Buttons/CreateResourceButton.vue";

    export default {
        components: {
            Card,
            Icon,
            CreateResourceButton,
        },

        props: [
            "create-button-label",
            "resourceLabel",
            "resourceName",
            "viaResource",
            "viaResourceId",
            "viaRelationship",
            "relationshipType",
            "authorizedToCreate",
            "authorizedToRelate",
        ],

        computed: {
            shouldShowButton() {
                return (
                    this.shouldShowCreateButton || this.shouldShowAttachButton
                );
            },

            shouldShowAttachButton() {
                return (
                    (this.relationshipType === "belongsToMany" ||
                        this.relationshipType === "morphToMany") &&
                    this.authorizedToRelate
                );
            },

            shouldShowCreateButton() {
                return (
                    this.authorizedToCreate &&
                    this.authorizedToRelate &&
                    !this.alreadyFilled
                );
            },

            createOrAttachButtonLabel() {
                return this.shouldShowAttachButton
                    ? __("Attach :resource", { resource: this.singularName })
                    : this.createButtonLabel;
            },

            buttonURL() {
                if (this.shouldShowAttachButton) {
                    return Nova.url(
                        `/resources/${this.viaResource}/${this.viaResourceId}/attach/${this.resourceName}`,
                        {
                            viaRelationship: this.viaRelationship,
                            polymorphic:
                                this.relationshipType === "morphToMany"
                                    ? "1"
                                    : "0",
                        },
                    );
                } else if (this.shouldShowCreateButton) {
                    return Nova.url(`/resources/${this.resourceName}/new`, {
                        viaResource: this.viaResource,
                        viaResourceId: this.viaResourceId,
                        viaRelationship: this.viaRelationship,
                        relationshipType: this.relationshipType,
                    });
                }
            },
        },
    };
</script>
