<template>
    <div
        class="field-grid"
        :class="{
            'stacked:field-grid': stacked,
            'lg:grid-cols-12': !fullWidth,
        }"
    >
        <slot />
    </div>
</template>

<script>
    export default {
        props: {
            stacked: {
                type: Boolean,
                default: false,
            },
            fullWidth: {
                type: Boolean,
                default: false,
            },
        },

        provide() {
            return {
                stacked: this.stacked,
            };
        },
    };
</script>

<style>
    @layer nova {
        .field-grid {
            @apply grid;

            &:not(.stacked\:field-grid) {
                @apply border-gray-400 border-t dark:border-gray-700;
                @apply @md/modal:border-t-0;
            }

            &.stacked\:field-grid {
                @apply gap-x-4 gap-y-2;
            }

            &.\@container\/peekable {
                @apply border-t-0;
            }
        }
    }
</style>
