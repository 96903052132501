<template>
    <modal
        :show="show"
        role="alertdialog"
        size="xl"
        @close="handleClose"
        :header="__('Restore') + ' ' + resourceInformation.singularLabel"
    >
        <form @submit.prevent="handleConfirm">
            <modal-content>
                <slot>
                    <p class="leading-normal">
                        {{
                            __(
                                "Are you sure you want to restore the selected resources?",
                            )
                        }}
                    </p>
                </slot>
            </modal-content>

            <modal-footer>
                <v-button
                    type="button"
                    variant="link"
                    :label="__('Cancel')"
                    severity="muted"
                    dusk="cancel-restore-button"
                    @click.prevent="handleClose"
                    size="sm"
                />

                <v-button
                    type="submit"
                    ref="confirmButton"
                    dusk="confirm-restore-button"
                    :loading="working"
                    severity="alert"
                    :label="__('Restore')"
                    size="sm"
                />
            </modal-footer>
        </form>
    </modal>
</template>

<script>
    import Modal from "@nova/components/Modals/Modal.vue";
    import ModalContent from "@nova/components/Modals/ModalContent.vue";
    import ModalFooter from "@nova/components/Modals/ModalFooter.vue";
    import VButton from "@nova/components/Buttons/VButton.vue";

    export default {
        emits: ["confirm", "close"],

        inject: ["resourceInformation"],

        components: {
            Modal,
            ModalContent,
            ModalFooter,
            VButton,
        },

        props: {
            show: { type: Boolean, default: false },
        },

        data: () => ({
            working: false,
        }),

        watch: {
            show(showing) {
                if (showing === false) {
                    this.working = false;
                }
            },
        },

        methods: {
            handleClose() {
                this.$emit("close");
                this.working = false;
            },

            handleConfirm() {
                this.$emit("confirm");
                this.working = true;
            },
        },
    };
</script>
