<script>
    import BaseChartMetric from "./BaseChartMetric.vue";

    export default {
        extends: BaseChartMetric,
        props: {
            /**
             * @property {Object=} range
             * @property {Number}  range.length
             * @property {String}  range.resolution ("Minute"|"Hour"|"Day"|"Week"|"Month")
             * @property {String}  range.unit ("minutes"|"hours"|"days"|"weeks"|"months")
             * @property {String}  range.start (Date Format "YYYY-MM-DDTHH:MM:SS-TT:TT")
             * @property {String}  range.end (Date Format "YYYY-MM-DDTHH:MM:SS-TT:TT")
             * @property {String}  range.timezone (e.g. "America/Chicago")
             */
            range: Object,
        },
    };
</script>
