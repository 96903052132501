<template>
    <loading-metric
        component="base-trend-metric"
        v-bind="$attrs"
    />
</template>

<script>
    import LoadingMetric from "@nova/components/Metrics/LoadingMetric.vue";

    export default {
        components: {
            LoadingMetric,
        },
    };
</script>
