<template>
    <div
        id="nova"
        class="my-4"
    >
        <app-head
            :title="title"
            :section="section"
            :subsection="subsection"
        />

        <header
            class="mb-4 mx-4 flex flex-col gap-x-4 sm:flex-row sm:justify-between items-center"
        >
            <h1
                id="page-title"
                dusk="page-title"
                class="m-0 p-0 text-lg leading-8 tracking-normal whitespace-nowrap"
            >
                {{ title }}
            </h1>
            <div id="page-actions">
                <template v-if="$slots.actions">
                    <slot name="actions" />
                </template>
            </div>
        </header>
        <div dusk="content">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: String,
            section: String,
            subsection: String,
        },
    };
</script>
