<template>
    <div
        class="flex"
        :class="justifyClass"
    >
        <badge
            :label="field.label"
            :extra-classes="field.typeClass"
        >
            <template #icon>
                <span
                    v-if="field.icon"
                    class="mr-1 -ml-1"
                >
                    <icon :name="field.icon" />
                </span>
            </template>
        </badge>
    </div>
</template>

<script>
    import DisplayField from "@nova/extendables/DisplayField.vue";

    export default {
        extends: DisplayField,
    };
</script>
