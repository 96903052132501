<template>
    <div
        class="flex items-center cursor-pointer select-none text-sm group hover:bg-black/5"
        @click="toggle"
    >
        <button
            type="button"
            class="m-0 w-10 rounded py-2 text-center text-xxs font-bold uppercase border group-hover:opacity-75"
            :class="{
                'bg-white border-gray-400': modelValue === null,
                'bg-green-600 border-green-700 text-white': modelValue === true,
                'bg-red-700 border-red-800 text-white': modelValue === false,
            }"
        >
            <span v-text="toggleLabel"></span>
        </button>

        <span class="ml-2">
            {{ label }}
        </span>
    </div>
</template>

<script>
    export default {
        emits: ["change", "update:modelValue"],

        props: {
            modelValue: {
                type: Boolean,
                default: null,
            },

            label: {
                type: String,
                default: null,
            },
        },

        methods: {
            toggle() {
                this.$emit("update:modelValue", this.nextValue);
                this.$emit("change", this.nextValue);
            },
        },

        computed: {
            nextValue() {
                if (this.modelValue === true) {
                    return false;
                } else if (this.modelValue === false) {
                    return null;
                } else {
                    return true;
                }
            },

            toggleLabel() {
                if (this.modelValue === true) {
                    return "Yes";
                } else if (this.modelValue === false) {
                    return "No";
                } else {
                    return "Any";
                }
            },
        },
    };
</script>
