<template>
    <div>
        <checkbox
            v-bind="$attrs"
            :dusk="`${resourceName}-with-trashed-checkbox`"
            :modelValue="withTrashed"
            @change="toggleWithTrashed"
            :label="__('With Trashed')"
        />
    </div>
</template>

<script>
    import Checkbox from "@nova/components/Inputs/Checkbox.vue";

    export default {
        components: {
            Checkbox,
        },

        emits: ["input"],

        inheritAttrs: false,

        props: {
            resourceName: String,
            withTrashed: Boolean,
        },

        methods: {
            toggleWithTrashed() {
                this.$emit("input");
            },
        },
    };
</script>
