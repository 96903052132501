import aspectRatio from "@tailwindcss/aspect-ratio";
import colors from "tailwindcss/colors";
import containerQueries from "@tailwindcss/container-queries";
import forms from "@tailwindcss/forms";
import plugin from "tailwindcss/plugin";
import typography from "@tailwindcss/typography";

export default {
    darkMode: "class",
    content: [
        "./resources/**/*.{php,vue,js}",
        "./eagle/**/Nova/Resources/*.php",
        "./eaglesys.info/**/*.{php,vue,js}",
        "./vendor/eagle-metal/*/resources/**/*.{php,vue,js}",
    ],
    safelist: [],
    theme: {
        colors: {
            transparent: "transparent",
            current: "currentColor",
            // brand colors
            gray: {
                // https://uicolors.app/edit?sv1=emp-gray:50-f7f7f7/100-eff0f0/200-e2e3e3/300-d1d3d4/400-bdbcbd/500-969495/600-737273/650-5f5f60/700-545455/750-474747/800-333333/850-272727/900-1c1c1c/950-161616
                50: "#F7F7F7",
                100: "#EFF0F0",
                200: "#E2E3E3",
                300: "#D1D3D4",
                400: "#BDBCBD",
                500: "#969495",
                600: "#737273",
                650: "#5F5F60",
                700: "#545455",
                750: "#474747",
                800: "#333333",
                850: "#272727",
                900: "#1C1C1C",
                950: "#161616",
            },
            blue: {
                // https://uicolors.app/edit?sv1=emp-navy:50-f3f6fc/100-e7eef7/200-c9daee/300-9abddf/400-6399cd/500-3f7cb8/600-2e629b/700-27507d/750-234369/800-223a58/900-18283e/950-101e2f
                50: "#f3f6fc",
                100: "#e7eef7",
                200: "#c9daee",
                300: "#9abddf",
                400: "#6399cd",
                500: "#3f7cb8",
                600: "#2e629b",
                700: "#27507d",
                750: "#234369",
                800: "#223a58",
                850: "#1d314e",
                900: "#18283e",
                950: "#101e2f",
                975: "#0d1a26",
            },
            orange: {
                // https://uicolors.app/edit?sv1=emp-orange:50-fff7ed/100-feedd6/200-fcd8ac/300-f9bc78/400-f69541/500-f37920/600-e45d12/700-bd4511/800-963816/900-792f15/950-411609
                50: "#fff7ed",
                100: "#feedd6",
                200: "#fcd8ac",
                300: "#f9bc78",
                400: "#f69541",
                500: "#f37920",
                600: "#e45d12",
                700: "#bd4511",
                800: "#963816",
                900: "#792f15",
                950: "#411609",
            },
            // tailwind default colors
            black: colors.black,
            green: colors.green,
            indigo: colors.indigo,
            red: colors.red,
            white: colors.white,
            yellow: colors.amber,
        },
        extend: {
            fontSize: {
                xxs: [
                    "0.625rem" /* 10px */,
                    {
                        lineHeight: "0.75rem" /* 12px */,
                    },
                ],
            },
            minWidth: {
                32: "8rem",
                64: "16rem",
            },
            animation: {
                fade: "fade 3s linear infinite",
                "progress-bar-stripes":
                    "progress-bar-stripes 1s linear infinite",
                "spin-slow": "spin 2s linear infinite",
            },
            backgroundImage: {
                striped: `linear-gradient(
                    45deg,
                    rgba(255, 255, 255, 0.15) 25%,
                    transparent 25%,
                    transparent 50%,
                    rgba(255, 255, 255, 0.15) 50%,
                    rgba(255, 255, 255, 0.15) 75%,
                    transparent 75%,
                    transparent
                );`,
            },
            keyframes: {
                fade: {
                    "0%, 100%": { opacity: 1 },
                    "50%": { opacity: 0 },
                },
                "progress-bar-stripes": {
                    "0%": {
                        "background-position-x": "1rem",
                    },
                },
            },
        },
    },
    plugins: [
        aspectRatio,
        forms({ strategy: "base" }),
        containerQueries,
        typography,
        plugin(function ({ addUtilities }) {
            addUtilities({
                ".place-items-end-start": {
                    "place-items": "end start",
                },
                ".place-self-end-start": {
                    "place-self": "end start",
                },
                ".place-items-start-end": {
                    "place-items": "start end",
                },
                ".place-self-start-end": {
                    "place-self": "start end",
                },
                ".place-self-center-end": {
                    "place-self": "center end",
                },
            });
        }),
    ],
};
