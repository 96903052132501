<template>
    <div
        :class="justifyClass"
        class="flex items-center"
    >
        <image-loader
            v-if="shouldShowLoader"
            :src="imageUrl"
            :max-width="parseInt(field.indexWidth || field.maxWidth)"
            :rounded="field.rounded"
            :aspect="field.aspect"
        />
        <span
            v-if="usesCustomizedDisplay && !imageUrl"
            class="break-words"
            :title="field.value"
        >
            {{ field.displayedAs }}
        </span>
        <p
            v-if="!usesCustomizedDisplay && !imageUrl"
            :class="`text-${field.textAlign}`"
            :title="field.value"
        >
            {{ __("&mdash;") }}
        </p>
    </div>
</template>

<script>
    import DisplayField from "@nova/extendables/DisplayField.vue";

    export default {
        extends: DisplayField,

        data: () => ({
            loading: false,
        }),

        computed: {
            shouldShowLoader() {
                return this.imageUrl;
            },

            imageUrl() {
                return this.field.thumbnailUrl || this.field.previewUrl;
            },
        },
    };
</script>
